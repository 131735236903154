import React from 'react'

interface Props{
    index: number
}

export const InterfacesHguSvg = ({index}: Props) => {
  return (
    <svg width="328" height="76" viewBox="0 0 328 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="17.6916" y="22.9551" width="291.964" height="45.7983" rx="13.5964" fill="#8083A3" fill-opacity="0.1" stroke="black" stroke-width="1.4312"/>
        <mask id="path-2-inside-1_3086_514" fill="white">
        <path d="M5.52649 36.5515H16.9761V50.8635H5.52649V36.5515Z"/>
        </mask>
            <path d="M5.52649 36.5515H16.9761V50.8635H5.52649V36.5515Z" fill="black"/>
            <path d="M5.52649 36.5515V35.1203H4.09529V36.5515H5.52649ZM5.52649 50.8635H4.09529V52.2947H5.52649V50.8635ZM5.52649 37.9827H16.9761V35.1203H5.52649V37.9827ZM16.9761 49.4323H5.52649V52.2947H16.9761V49.4323ZM6.95769 50.8635V36.5515H4.09529V50.8635H6.95769Z" fill="black" mask="url(#path-2-inside-1_3086_514)"/>
            <mask id="path-4-inside-2_3086_514" fill="white">
            <path d="M310.371 36.5515H320.39V49.4323H310.371V36.5515Z"/>
        </mask>
        <path d="M310.371 36.5515H320.39V49.4323H310.371V36.5515Z" fill="black"/>
        <path d="M320.39 36.5515H321.821V35.1203H320.39V36.5515ZM320.39 49.4323V50.8635H321.821V49.4323H320.39ZM310.371 37.9827H320.39V35.1203H310.371V37.9827ZM318.958 36.5515V49.4323H321.821V36.5515H318.958ZM320.39 48.0011H310.371V50.8635H320.39V48.0011Z" fill="black" mask="url(#path-4-inside-2_3086_514)"/>
        <path d="M6.95755 37.267L3.37956 37.267L1.23279 37.267L4.09516 0.771485" stroke="black" stroke-width="1.4312"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.80886 0.111907L1.94647 36.6074L0.519653 36.4955L3.38205 0L4.80886 0.111907Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M323.951 75.4319L323.296 75.4309L4.85449 74.9375L3.29947 74.9351L2.64203 74.9341L2.58746 74.2789L0.564516 49.9874L0.499972 49.2124L1.27762 49.2124L7.00241 49.2124L7.00241 50.6436L2.0553 50.6436L3.95914 73.5049L4.85672 73.5063L322.642 73.9987L324.851 49.2688L319.13 49.2688L319.13 47.8376L325.633 47.8376L326.415 47.8376L326.346 48.6168L324.01 74.7789L323.951 75.4319Z" fill="black"/>
        <path d="M319.674 48.7166C324.826 48.7166 326.114 48.7166 326.114 48.7166" stroke="black" stroke-width="1.4312"/>
        <rect x="89.2512" y="38.6983" width="20.0367" height="14.312" stroke="black" stroke-width="1.4312"/>
        <circle cx="99.2694" cy="45.8542" r="4.29359" stroke="black" stroke-width="1.4312"/>
        <rect x="36.2974" y="37.2671" width="15.7432" height="17.1744" stroke="black" stroke-width="1.4312"/>
        <circle cx="44.1692" cy="43.7074" r="3.57799" stroke="black" stroke-width="1.4312"/>
        <circle cx="261.711" cy="46.5699" r="2.86239" fill="black"/>
        <circle cx="276.022" cy="46.5699" r="2.86239" fill="black"/>
        <circle cx="291.766" cy="46.5164" r="1.4312" fill="black"/>
        {index === 1 ? 
        <>
            <path d="M149.019 55.157L168.434 55.157C168.64 55.157 168.807 54.9897 168.807 54.7836L168.807 38.356C168.807 38.1495 168.64 37.9826 168.434 37.9826L149.019 37.9826C148.813 37.9826 148.646 38.1495 148.646 38.356L148.646 54.7836C148.646 54.9897 148.813 55.157 149.019 55.157ZM149.393 38.7294L168.06 38.7294L168.06 54.4103L149.393 54.4103L149.393 38.7294Z" fill="blue"/>
            <path d="M165.447 41.7161L161.34 41.7161L161.34 40.596C161.34 40.3896 161.173 40.2227 160.967 40.2227L156.486 40.2227C156.28 40.2227 156.113 40.3896 156.113 40.596L156.113 41.7161L152.006 41.7161C151.8 41.7161 151.633 41.883 151.633 42.0894L151.633 52.5434C151.633 52.7495 151.8 52.9167 152.006 52.9167L165.447 52.9167C165.653 52.9167 165.82 52.7495 165.82 52.5434L165.82 42.0894C165.82 41.883 165.653 41.7161 165.447 41.7161ZM165.074 52.17L163.58 52.17L163.58 49.5566C163.58 49.3505 163.413 49.1832 163.207 49.1832C163.001 49.1832 162.833 49.3505 162.833 49.5566L162.833 52.17L161.34 52.17L161.34 49.5566C161.34 49.3505 161.173 49.1832 160.967 49.1832C160.761 49.1832 160.593 49.3505 160.593 49.5566L160.593 52.17L159.1 52.17L159.1 49.5566C159.1 49.3505 158.933 49.1832 158.727 49.1832C158.52 49.1832 158.353 49.3505 158.353 49.5566L158.353 52.17L156.86 52.17L156.86 49.5566C156.86 49.3505 156.693 49.1832 156.486 49.1832C156.28 49.1832 156.113 49.3505 156.113 49.5566L156.113 52.17L154.62 52.17L154.62 49.5566C154.62 49.3505 154.453 49.1832 154.246 49.1832C154.04 49.1832 153.873 49.3505 153.873 49.5566L153.873 52.17L152.38 52.17L152.38 42.4628L156.486 42.4628C156.693 42.4628 156.86 42.2959 156.86 42.0894L156.86 40.9694L160.593 40.9694L160.593 42.0894C160.593 42.2959 160.761 42.4628 160.967 42.4628L165.074 42.4628L165.074 52.17Z" fill="blue"/>
        </>
        :
        <>
            <path d="M149.019 55.157L168.434 55.157C168.64 55.157 168.807 54.9897 168.807 54.7836L168.807 38.356C168.807 38.1495 168.64 37.9826 168.434 37.9826L149.019 37.9826C148.813 37.9826 148.646 38.1495 148.646 38.356L148.646 54.7836C148.646 54.9897 148.813 55.157 149.019 55.157ZM149.393 38.7294L168.06 38.7294L168.06 54.4103L149.393 54.4103L149.393 38.7294Z" fill="black"/>
            <path d="M165.447 41.7161L161.34 41.7161L161.34 40.596C161.34 40.3896 161.173 40.2227 160.967 40.2227L156.486 40.2227C156.28 40.2227 156.113 40.3896 156.113 40.596L156.113 41.7161L152.006 41.7161C151.8 41.7161 151.633 41.883 151.633 42.0894L151.633 52.5434C151.633 52.7495 151.8 52.9167 152.006 52.9167L165.447 52.9167C165.653 52.9167 165.82 52.7495 165.82 52.5434L165.82 42.0894C165.82 41.883 165.653 41.7161 165.447 41.7161ZM165.074 52.17L163.58 52.17L163.58 49.5566C163.58 49.3505 163.413 49.1832 163.207 49.1832C163.001 49.1832 162.833 49.3505 162.833 49.5566L162.833 52.17L161.34 52.17L161.34 49.5566C161.34 49.3505 161.173 49.1832 160.967 49.1832C160.761 49.1832 160.593 49.3505 160.593 49.5566L160.593 52.17L159.1 52.17L159.1 49.5566C159.1 49.3505 158.933 49.1832 158.727 49.1832C158.52 49.1832 158.353 49.3505 158.353 49.5566L158.353 52.17L156.86 52.17L156.86 49.5566C156.86 49.3505 156.693 49.1832 156.486 49.1832C156.28 49.1832 156.113 49.3505 156.113 49.5566L156.113 52.17L154.62 52.17L154.62 49.5566C154.62 49.3505 154.453 49.1832 154.246 49.1832C154.04 49.1832 153.873 49.3505 153.873 49.5566L153.873 52.17L152.38 52.17L152.38 42.4628L156.486 42.4628C156.693 42.4628 156.86 42.2959 156.86 42.0894L156.86 40.9694L160.593 40.9694L160.593 42.0894C160.593 42.2959 160.761 42.4628 160.967 42.4628L165.074 42.4628L165.074 52.17Z" fill="black"/>
        </>
        }
        {
            index === 2 ?
            <>
                <path d="M174.781 55.157L194.195 55.157C194.402 55.157 194.569 54.9897 194.569 54.7836L194.569 38.356C194.569 38.1495 194.402 37.9826 194.195 37.9826L174.781 37.9826C174.575 37.9826 174.408 38.1495 174.408 38.356L174.408 54.7836C174.408 54.9897 174.575 55.157 174.781 55.157ZM175.154 38.7294L193.822 38.7294L193.822 54.4103L175.154 54.4103L175.154 38.7294Z" fill="blue"/>
                <path d="M191.209 41.7161L187.102 41.7161L187.102 40.596C187.102 40.3896 186.935 40.2227 186.728 40.2227L182.248 40.2227C182.042 40.2227 181.875 40.3896 181.875 40.596L181.875 41.7161L177.768 41.7161C177.561 41.7161 177.395 41.883 177.395 42.0894L177.395 52.5434C177.395 52.7495 177.561 52.9167 177.768 52.9167L191.209 52.9167C191.415 52.9167 191.582 52.7495 191.582 52.5434L191.582 42.0894C191.582 41.883 191.415 41.7161 191.209 41.7161ZM190.835 52.17L189.342 52.17L189.342 49.5566C189.342 49.3505 189.175 49.1832 188.969 49.1832C188.762 49.1832 188.595 49.3505 188.595 49.5566L188.595 52.17L187.102 52.17L187.102 49.5566C187.102 49.3505 186.935 49.1832 186.728 49.1832C186.522 49.1832 186.355 49.3505 186.355 49.5566L186.355 52.17L184.862 52.17L184.862 49.5566C184.862 49.3505 184.694 49.1832 184.488 49.1832C184.282 49.1832 184.115 49.3505 184.115 49.5566L184.115 52.17L182.622 52.17L182.622 49.5566C182.622 49.3505 182.455 49.1832 182.248 49.1832C182.042 49.1832 181.875 49.3505 181.875 49.5566L181.875 52.17L180.381 52.17L180.381 49.5566C180.381 49.3505 180.214 49.1832 180.008 49.1832C179.802 49.1832 179.635 49.3505 179.635 49.5566L179.635 52.17L178.141 52.17L178.141 42.4628L182.248 42.4628C182.455 42.4628 182.622 42.2959 182.622 42.0894L182.622 40.9694L186.355 40.9694L186.355 42.0894C186.355 42.2959 186.522 42.4628 186.728 42.4628L190.835 42.4628L190.835 52.17Z" fill="blue"/>
            </>
            :
            <>
                <path d="M174.781 55.157L194.195 55.157C194.402 55.157 194.569 54.9897 194.569 54.7836L194.569 38.356C194.569 38.1495 194.402 37.9826 194.195 37.9826L174.781 37.9826C174.575 37.9826 174.408 38.1495 174.408 38.356L174.408 54.7836C174.408 54.9897 174.575 55.157 174.781 55.157ZM175.154 38.7294L193.822 38.7294L193.822 54.4103L175.154 54.4103L175.154 38.7294Z" fill="black"/>
                <path d="M191.209 41.7161L187.102 41.7161L187.102 40.596C187.102 40.3896 186.935 40.2227 186.728 40.2227L182.248 40.2227C182.042 40.2227 181.875 40.3896 181.875 40.596L181.875 41.7161L177.768 41.7161C177.561 41.7161 177.395 41.883 177.395 42.0894L177.395 52.5434C177.395 52.7495 177.561 52.9167 177.768 52.9167L191.209 52.9167C191.415 52.9167 191.582 52.7495 191.582 52.5434L191.582 42.0894C191.582 41.883 191.415 41.7161 191.209 41.7161ZM190.835 52.17L189.342 52.17L189.342 49.5566C189.342 49.3505 189.175 49.1832 188.969 49.1832C188.762 49.1832 188.595 49.3505 188.595 49.5566L188.595 52.17L187.102 52.17L187.102 49.5566C187.102 49.3505 186.935 49.1832 186.728 49.1832C186.522 49.1832 186.355 49.3505 186.355 49.5566L186.355 52.17L184.862 52.17L184.862 49.5566C184.862 49.3505 184.694 49.1832 184.488 49.1832C184.282 49.1832 184.115 49.3505 184.115 49.5566L184.115 52.17L182.622 52.17L182.622 49.5566C182.622 49.3505 182.455 49.1832 182.248 49.1832C182.042 49.1832 181.875 49.3505 181.875 49.5566L181.875 52.17L180.381 52.17L180.381 49.5566C180.381 49.3505 180.214 49.1832 180.008 49.1832C179.802 49.1832 179.635 49.3505 179.635 49.5566L179.635 52.17L178.141 52.17L178.141 42.4628L182.248 42.4628C182.455 42.4628 182.622 42.2959 182.622 42.0894L182.622 40.9694L186.355 40.9694L186.355 42.0894C186.355 42.2959 186.522 42.4628 186.728 42.4628L190.835 42.4628L190.835 52.17Z" fill="black"/>
            </>
        }
        {
            index === 3 ? 
            <>
                <path d="M200.542 55.157L219.957 55.157C220.163 55.157 220.33 54.9897 220.33 54.7836L220.33 38.356C220.33 38.1495 220.163 37.9826 219.957 37.9826L200.542 37.9826C200.336 37.9826 200.169 38.1495 200.169 38.356L200.169 54.7836C200.169 54.9897 200.336 55.157 200.542 55.157ZM200.916 38.7294L219.583 38.7294L219.583 54.4103L200.916 54.4103L200.916 38.7294Z" fill="blue"/>
                <path d="M216.97 41.7161L212.863 41.7161L212.863 40.596C212.863 40.3896 212.696 40.2227 212.49 40.2227L208.01 40.2227C207.803 40.2227 207.636 40.3896 207.636 40.596L207.636 41.7161L203.529 41.7161C203.323 41.7161 203.156 41.883 203.156 42.0894L203.156 52.5434C203.156 52.7495 203.323 52.9167 203.529 52.9167L216.97 52.9167C217.176 52.9167 217.343 52.7495 217.343 52.5434L217.343 42.0894C217.343 41.883 217.176 41.7161 216.97 41.7161ZM216.597 52.17L215.103 52.17L215.103 49.5566C215.103 49.3505 214.936 49.1832 214.73 49.1832C214.524 49.1832 214.357 49.3505 214.357 49.5566L214.357 52.17L212.863 52.17L212.863 49.5566C212.863 49.3505 212.696 49.1832 212.49 49.1832C212.284 49.1832 212.116 49.3505 212.116 49.5566L212.116 52.17L210.623 52.17L210.623 49.5566C210.623 49.3505 210.456 49.1832 210.25 49.1832C210.044 49.1832 209.876 49.3505 209.876 49.5566L209.876 52.17L208.383 52.17L208.383 49.5566C208.383 49.3505 208.216 49.1832 208.01 49.1832C207.803 49.1832 207.636 49.3505 207.636 49.5566L207.636 52.17L206.143 52.17L206.143 49.5566C206.143 49.3505 205.976 49.1832 205.769 49.1832C205.563 49.1832 205.396 49.3505 205.396 49.5566L205.396 52.17L203.903 52.17L203.903 42.4628L208.01 42.4628C208.216 42.4628 208.383 42.2959 208.383 42.0894L208.383 40.9694L212.116 40.9694L212.116 42.0894C212.116 42.2959 212.284 42.4628 212.49 42.4628L216.597 42.4628L216.597 52.17Z" fill="blue"/>
            </>
            : 
            <>
                <path d="M200.542 55.157L219.957 55.157C220.163 55.157 220.33 54.9897 220.33 54.7836L220.33 38.356C220.33 38.1495 220.163 37.9826 219.957 37.9826L200.542 37.9826C200.336 37.9826 200.169 38.1495 200.169 38.356L200.169 54.7836C200.169 54.9897 200.336 55.157 200.542 55.157ZM200.916 38.7294L219.583 38.7294L219.583 54.4103L200.916 54.4103L200.916 38.7294Z" fill="black"/>
                <path d="M216.97 41.7161L212.863 41.7161L212.863 40.596C212.863 40.3896 212.696 40.2227 212.49 40.2227L208.01 40.2227C207.803 40.2227 207.636 40.3896 207.636 40.596L207.636 41.7161L203.529 41.7161C203.323 41.7161 203.156 41.883 203.156 42.0894L203.156 52.5434C203.156 52.7495 203.323 52.9167 203.529 52.9167L216.97 52.9167C217.176 52.9167 217.343 52.7495 217.343 52.5434L217.343 42.0894C217.343 41.883 217.176 41.7161 216.97 41.7161ZM216.597 52.17L215.103 52.17L215.103 49.5566C215.103 49.3505 214.936 49.1832 214.73 49.1832C214.524 49.1832 214.357 49.3505 214.357 49.5566L214.357 52.17L212.863 52.17L212.863 49.5566C212.863 49.3505 212.696 49.1832 212.49 49.1832C212.284 49.1832 212.116 49.3505 212.116 49.5566L212.116 52.17L210.623 52.17L210.623 49.5566C210.623 49.3505 210.456 49.1832 210.25 49.1832C210.044 49.1832 209.876 49.3505 209.876 49.5566L209.876 52.17L208.383 52.17L208.383 49.5566C208.383 49.3505 208.216 49.1832 208.01 49.1832C207.803 49.1832 207.636 49.3505 207.636 49.5566L207.636 52.17L206.143 52.17L206.143 49.5566C206.143 49.3505 205.976 49.1832 205.769 49.1832C205.563 49.1832 205.396 49.3505 205.396 49.5566L205.396 52.17L203.903 52.17L203.903 42.4628L208.01 42.4628C208.216 42.4628 208.383 42.2959 208.383 42.0894L208.383 40.9694L212.116 40.9694L212.116 42.0894C212.116 42.2959 212.284 42.4628 212.49 42.4628L216.597 42.4628L216.597 52.17Z" fill="black"/>
            </>
        }
        {
            index === 4 ? 
            <>
                <path d="M226.304 55.157L245.718 55.157C245.925 55.157 246.092 54.9897 246.092 54.7836L246.092 38.356C246.092 38.1495 245.925 37.9826 245.718 37.9826L226.304 37.9826C226.098 37.9826 225.931 38.1495 225.931 38.356L225.931 54.7836C225.931 54.9897 226.098 55.157 226.304 55.157ZM226.677 38.7294L245.345 38.7294L245.345 54.4103L226.677 54.4103L226.677 38.7294Z" fill="blue"/>
                <path d="M242.732 41.7161L238.625 41.7161L238.625 40.596C238.625 40.3896 238.458 40.2227 238.251 40.2227L233.771 40.2227C233.565 40.2227 233.398 40.3896 233.398 40.596L233.398 41.7161L229.291 41.7161C229.084 41.7161 228.918 41.883 228.918 42.0894L228.918 52.5434C228.918 52.7495 229.084 52.9167 229.291 52.9167L242.732 52.9167C242.938 52.9167 243.105 52.7495 243.105 52.5434L243.105 42.0894C243.105 41.883 242.938 41.7161 242.732 41.7161ZM242.358 52.17L240.865 52.17L240.865 49.5566C240.865 49.3505 240.698 49.1832 240.492 49.1832C240.286 49.1832 240.118 49.3505 240.118 49.5566L240.118 52.17L238.625 52.17L238.625 49.5566C238.625 49.3505 238.458 49.1832 238.251 49.1832C238.045 49.1832 237.878 49.3505 237.878 49.5566L237.878 52.17L236.385 52.17L236.385 49.5566C236.385 49.3505 236.217 49.1832 236.011 49.1832C235.805 49.1832 235.638 49.3505 235.638 49.5566L235.638 52.17L234.145 52.17L234.145 49.5566C234.145 49.3505 233.978 49.1832 233.771 49.1832C233.565 49.1832 233.398 49.3505 233.398 49.5566L233.398 52.17L231.904 52.17L231.904 49.5566C231.904 49.3505 231.738 49.1832 231.531 49.1832C231.325 49.1832 231.158 49.3505 231.158 49.5566L231.158 52.17L229.664 52.17L229.664 42.4628L233.771 42.4628C233.978 42.4628 234.145 42.2959 234.145 42.0894L234.145 40.9694L237.878 40.9694L237.878 42.0894C237.878 42.2959 238.045 42.4628 238.251 42.4628L242.358 42.4628L242.358 52.17Z" fill="blue"/>
            </>
            : 
            <>
                <path d="M226.304 55.157L245.718 55.157C245.925 55.157 246.092 54.9897 246.092 54.7836L246.092 38.356C246.092 38.1495 245.925 37.9826 245.718 37.9826L226.304 37.9826C226.098 37.9826 225.931 38.1495 225.931 38.356L225.931 54.7836C225.931 54.9897 226.098 55.157 226.304 55.157ZM226.677 38.7294L245.345 38.7294L245.345 54.4103L226.677 54.4103L226.677 38.7294Z" fill="black"/>
                <path d="M242.732 41.7161L238.625 41.7161L238.625 40.596C238.625 40.3896 238.458 40.2227 238.251 40.2227L233.771 40.2227C233.565 40.2227 233.398 40.3896 233.398 40.596L233.398 41.7161L229.291 41.7161C229.084 41.7161 228.918 41.883 228.918 42.0894L228.918 52.5434C228.918 52.7495 229.084 52.9167 229.291 52.9167L242.732 52.9167C242.938 52.9167 243.105 52.7495 243.105 52.5434L243.105 42.0894C243.105 41.883 242.938 41.7161 242.732 41.7161ZM242.358 52.17L240.865 52.17L240.865 49.5566C240.865 49.3505 240.698 49.1832 240.492 49.1832C240.286 49.1832 240.118 49.3505 240.118 49.5566L240.118 52.17L238.625 52.17L238.625 49.5566C238.625 49.3505 238.458 49.1832 238.251 49.1832C238.045 49.1832 237.878 49.3505 237.878 49.5566L237.878 52.17L236.385 52.17L236.385 49.5566C236.385 49.3505 236.217 49.1832 236.011 49.1832C235.805 49.1832 235.638 49.3505 235.638 49.5566L235.638 52.17L234.145 52.17L234.145 49.5566C234.145 49.3505 233.978 49.1832 233.771 49.1832C233.565 49.1832 233.398 49.3505 233.398 49.5566L233.398 52.17L231.904 52.17L231.904 49.5566C231.904 49.3505 231.738 49.1832 231.531 49.1832C231.325 49.1832 231.158 49.3505 231.158 49.5566L231.158 52.17L229.664 52.17L229.664 42.4628L233.771 42.4628C233.978 42.4628 234.145 42.2959 234.145 42.0894L234.145 40.9694L237.878 40.9694L237.878 42.0894C237.878 42.2959 238.045 42.4628 238.251 42.4628L242.358 42.4628L242.358 52.17Z" fill="black"/>
            </>
        }
        <path d="M123.258 55.157L142.673 55.157C142.879 55.157 143.046 54.9897 143.046 54.7836L143.046 38.356C143.046 38.1495 142.879 37.9826 142.673 37.9826L123.258 37.9826C123.052 37.9826 122.885 38.1495 122.885 38.356L122.885 54.7836C122.885 54.9897 123.052 55.157 123.258 55.157ZM123.631 38.7294L142.299 38.7294L142.299 54.4103L123.631 54.4103L123.631 38.7294Z" fill="#020202"/>
        <path d="M139.686 41.7161L135.579 41.7161L135.579 40.596C135.579 40.3896 135.412 40.2227 135.205 40.2227L130.725 40.2227C130.519 40.2227 130.352 40.3896 130.352 40.596L130.352 41.7161L126.245 41.7161C126.038 41.7161 125.872 41.883 125.872 42.0894L125.872 52.5434C125.872 52.7495 126.038 52.9167 126.245 52.9167L139.686 52.9167C139.892 52.9167 140.059 52.7495 140.059 52.5434L140.059 42.0894C140.059 41.883 139.892 41.7161 139.686 41.7161ZM139.312 52.17L137.819 52.17L137.819 49.5566C137.819 49.3505 137.652 49.1832 137.446 49.1832C137.24 49.1832 137.072 49.3505 137.072 49.5566L137.072 52.17L135.579 52.17L135.579 49.5566C135.579 49.3505 135.412 49.1832 135.205 49.1832C134.999 49.1832 134.832 49.3505 134.832 49.5566L134.832 52.17L133.339 52.17L133.339 49.5566C133.339 49.3505 133.171 49.1832 132.965 49.1832C132.759 49.1832 132.592 49.3505 132.592 49.5566L132.592 52.17L131.099 52.17L131.099 49.5566C131.099 49.3505 130.932 49.1832 130.725 49.1832C130.519 49.1832 130.352 49.3505 130.352 49.5566L130.352 52.17L128.858 52.17L128.858 49.5566C128.858 49.3505 128.692 49.1832 128.485 49.1832C128.279 49.1832 128.112 49.3505 128.112 49.5566L128.112 52.17L126.618 52.17L126.618 42.4628L130.725 42.4628C130.932 42.4628 131.099 42.2959 131.099 42.0894L131.099 40.9694L134.832 40.9694L134.832 42.0894C134.832 42.2959 134.999 42.4628 135.205 42.4628L139.312 42.4628L139.312 52.17Z" fill="black"/>
        <path d="M319.997 37.2818H326.5L324.474 1.59522M322.916 1.59169L324.474 1.59522M3.96814 0.866943L324.474 1.59522" stroke="black" stroke-width="1.4312"/>
    </svg>

  )
}
