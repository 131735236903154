import * as React from 'react';

import styles from "./Alert.module.css"

import { CalendarDays, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, CircleX, Flame, FlameIcon, OctagonAlert, OctagonX, RefreshCcw, 
  Search, TriangleAlert, ArrowUp, ArrowDown 
} from 'lucide-react'

import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import DateFormated from '../../../../../components/ReturnDate/DateFormated';
import { AlertsInterfaceData } from '../../../../../interfaces/AlertsInterface';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { parseISO } from 'date-fns';

interface Props{
  alertsInfo: AlertsInterfaceData
  id: string
}

type mapped = {
  qttAlerts: number,
  criticAlerts: number,
  seriousAlerts: number,
  moderatedAlerts: number,
  softAlerts: number
}

const Alert = ({ alertsInfo, id }: Props) => {
  const [filterInput, setFilterInput] = React.useState("")
  const [ dados, setDados] = React.useState(alertsInfo.alertas)
  const [page, setPage] = React.useState(1)
  const [itensPerPage, setItensPerPage] = React.useState(5)
  const [order, setOrder] = React.useState("desc")
  const [mappedData, setMappedData] = React.useState<mapped>(
    {
      qttAlerts: 0,
      criticAlerts: 0,
      seriousAlerts: 0,
      moderatedAlerts: 0,
      softAlerts: 0
    }
  )
    
  React.useEffect(() => {
    const mapping = () => {
      let mapped = {
        qttAlerts: dados.length,
        criticAlerts: 0,
        seriousAlerts: 0,
        moderatedAlerts: 0,
        softAlerts: 0
      }
      for(let i = 0; i < dados.length; i++ ){
        if(dados[i].detection_code === 1){
          dados[i].detection_code = "leve";
          
        }else if(dados[i].detection_code === 2){
          dados[i].detection_code = "moderado";
          
        }else if(dados[i].detection_code === 3){
          dados[i].detection_code = "grave";
          
        }else if(dados[i].detection_code === 4){
          dados[i].detection_code = "critico";
          
        }
      }

      dados.forEach(obj => {
        if(obj.detection_code === "leve"){
          mapped.softAlerts += 1;
        }else if(obj.detection_code === "moderado"){
          mapped.moderatedAlerts += 1;
        }else if(obj.detection_code === "grave"){
          mapped.seriousAlerts += 1;
        }else{
          mapped.criticAlerts += 1;
        }
      })
      setMappedData(mapped); 
    }
    mapping()
  }, [dados, id])
  
  function goBackPage (){
    setPage(page - 1)
  }

  function goNextPag(){
    setPage(page + 1)
  }

  function goLastPage(){
    setPage(totalPages)
  }

  function goFirstPage(){
    setPage(1)
  }

  // .sort(order === "asc" ? (a, b) => new Date(a.execution_time) - newDate(b.execution_interval) : new Date(b.execution_time) - new Date(a.execution_interval))

  const filteredData = dados.filter(query => query.detection_description.includes(filterInput)
    || query.execution_algorithm.includes(filterInput)
    || query.execution_interval.includes(filterInput)
    || (typeof query.detection_code === "string" && query.detection_code.includes(filterInput))
  )

  if(order === "desc"){
    filteredData.sort((a, b) => new Date(parseISO(b.execution_time)).getTime()- new Date(parseISO(a.execution_time)).getTime())
  }else{
    filteredData.sort((a, b) => new Date(parseISO(a.execution_time)).getTime()- new Date(parseISO(b.execution_time)).getTime()) 
  }

  const totalPages = Math.ceil(filteredData.length / itensPerPage)   

  const autoCompleteList: string[] = ["cobertura", "grave", "moderado", "neutral", "very likely", "likely"]

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>{mappedData.qttAlerts} Alertas</h1>
        <div className={styles.midInfo}>
          <div className={styles.criticInfoTop}>
            <span className={styles.errorElement} onClick={() => setFilterInput("critico")}>
              <div>
                <Flame />
              </div>
              <p>{mappedData.criticAlerts} Críticos</p>
            </span>
          </div>
          <div className={styles.criticInfoTop}  onClick={() => setFilterInput("grave")}>
            <span className={styles.alertElement}>
              <div>
                <OctagonX />
              </div>
              <p>{mappedData.seriousAlerts} Graves</p>
            </span>
          </div>
          <div className={styles.criticInfoTop}  onClick={() => setFilterInput("moderado")}>
            <span className={styles.moderatedAlert}>
              <div >
                <OctagonAlert />
              </div>
              <p>{mappedData.moderatedAlerts} Moderados</p>
            </span>
          </div>
        </div>
        <span className={styles.atualizationInfo}>
          <RefreshCcw />
          <p>Última atualização: <DateFormated date={alertsInfo.date}/></p>
        </span>
      </header>
      <main className={styles.content}>
        <article className={styles.topContainer}>
          <div className={styles.controledInput}>
            <Autocomplete
              freeSolo
              options={autoCompleteList.map((option) => option)}
              onInputChange={(event, newInputValue) => {
                setFilterInput(newInputValue);
              }}
              renderInput={(params) => 
                <TextField {...params} 
                placeholder='Busque por todos os dispositivos' 
                variant='standard'
                value={filterInput}
                onChange={e => setFilterInput(e.target.value)} 
                />}
            />
          <Search />
          </div>
          <section className={styles.details}>
            <span className={styles.rating}>
              <Typography component="legend" color="#94A5C8" sx={{textAlign: "center"}}>Index do HGU</Typography>
              <Rating name="hguIndex" sx={{color: '#7385c5'}} value={5} readOnly />
            </span>
            <span>
              <CalendarDays />
              <p>Últimos 7 dias</p>
            </span>
          </section>
        </article>
          <hr style={{ borderColor: "rgb(148 165 200 / 50%)"}} />
          {dados.length > 0 &&
            <div className={styles.tableControl}>
              <table>
                <thead className={styles.thead}>
                  <tr style={{borderBottomWidth: "1px", borderColor: "rgba(255 255 255 / 0.1)"}}>
                    <th className={styles.th}>Tipo</th>
                    <th className={styles.th}>Categoria</th>
                    <th className={styles.th}>Sub-Categoria</th>
                    <th className={styles.thData}>
                      Data {order === "asc" ? 
                      <IconButton>
                        <ArrowUp onClick={() => setOrder("desc")}/>
                       </IconButton>
                      : order === "desc" ? 
                      <IconButton>
                        <ArrowDown onClick={() => setOrder("asc")} /> 
                      </IconButton>
                      : <></>}
                    </th>
                    <th className={styles.th}>Intervalo</th>
                    <th className={styles.th}></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.slice((page - 1) * itensPerPage, page * itensPerPage).map((alert, index) => (
                    <>
                    {/* <span className={alert.errorType === "critico" ? styles.errorElement : styles.alertElement}></span> */}
                      <tr key={index} className={index % 2 === 1 && styles.colored}>
                        <td className={styles.criticInfo}>
                          <span className={alert.detection_code === "leve" ? styles.softAlert
                            : alert.detection_code === "moderado" ? styles.moderatedAlert
                            : alert.detection_code === "grave" ? styles.alertElement
                            : styles.errorElement 
                          }>
                            <div>
                              {
                                  alert.detection_code === 'leve' ? <CircleX  color='rgb(251, 255, 0)'/>
                                : alert.detection_code === "moderado" ? <OctagonAlert />
                                : alert.detection_code === "grave" ? <TriangleAlert color='orange' />
                                : <FlameIcon />
                              }
                            </div>
                              {alert.detection_code === "leve" ? <>Leve</>
                              : alert.detection_code === "moderado" ? <>Moderado</>
                              : alert.detection_code === "grave" ? <>Grave</>
                              : <>Critico</>
                            }
                          </span>
                        </td>
                        <td>coverage</td>
                        <td>{alert.execution_algorithm}</td>
                        <td><DateFormated date={alert.execution_time} /></td>
                        <td>{alert.execution_interval}</td>
                        <td><Button disabled variant="outlined">Detalhes</Button></td>
                      </tr>
                    </>
                  ))}
                </tbody>
                <tfoot>
                  <td colSpan={3} > 
                    Mostrando
                    <select style={{margin: "0 5px"}} value={itensPerPage} onChange={e => setItensPerPage(parseInt(e.target.value))}>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                    </select> 
                     de {filteredData.length} itens
                  </td>
                  <td colSpan={2} style={{textAlign: "right"}}>
                    <div>
                      <span>Pagina {page} de {totalPages} </span>
                      <div>
                        <button className={styles.buttonPage} onClick={goFirstPage} disabled={page ===1}>
                          <ChevronsLeft />
                        </button>
                        <button className={styles.buttonPage} onClick={goBackPage} disabled={page ===1}>
                          <ChevronLeft />
                        </button>
                        <button className={styles.buttonPage} onClick={goNextPag} disabled={page === totalPages}>
                          <ChevronRight />
                        </button>
                        <button className={styles.buttonPage} onClick={goLastPage} disabled={page === totalPages}>
                          <ChevronsRight />
                        </button>
                      </div>
                    </div>
                  </td>
                </tfoot>
              </table>
            </div>
          }
          {dados.length === 0 && <h2>Nenhum Alerta.</h2> }
      </main>
    </div>
  )
}

export default Alert