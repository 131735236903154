
import { Wifi } from "lucide-react"

import { DhcpTable, Summary } from "../../../../../interfaces/DeviceMap"

import Card from "@mui/material/Card"

import styles from "./DeviceMap.module.css"



interface Props {
  title: string,
  summary: Summary | undefined,
  data: DhcpTable[] | undefined,
}

const CardsDisp = ({ title, summary , data } : Props) => {

  return (
    <Card className={styles.content} variant="outlined" sx={{borderRadius: "10px", boxShadow: "-9px 10px 15px #c9c9c9c9"}}>
      <section>
        <div>
          <Wifi className={styles.iconHeader} />
          <p>{title}</p>
        </div>
        <h2>
          {title === "WiFi 2.4GHz" ? 
          <>
          {
            summary ?
            summary.qtt_wifi24 
            : 0

          }
          </>
          : <>
          {
            summary ?
            summary.qtt_wifi5
            : 0

          }
          </>
        }
        </h2>
        <p className={styles.connectedDisp}>Conectados</p>
      </section>
      {data && data?.length > 0 ?
      <>
        {data.map((item) => (
          <article className={styles.devicesMapped}>
              <header className={styles.header}>
                <h3 style={{textAlign: "center", margin: "2px 0"}}>{item.host_name}</h3>
              </header>
              <div className={styles.contentBody}>
                <span className={styles.wifiRadio}>
                  <Wifi />
                  <div>
                    <p className={styles.pHeader}>{item.radio}</p>
                    <p style={{textAlign: "start"}}>{item.rssi} dBm</p>
                  </div>
                </span>
                <span className={styles.info}>
                  <p className={styles.pHeader}>IP</p>
                  <p>{item.ip_addr}</p>
                </span>
                <span className={styles.info}>
                  <p className={styles.pHeader}>MAC</p>
                  <p>{item.mac_addr}</p>
                </span>
              </div>
          </article>
        ))}
      </>
      :
      <div>
        <p style={{textAlign: "center", fontWeight: "bold", fontSize: "1.5rem"}}>Sem informações</p>
      </div>}
    </Card>
  )
}

export default CardsDisp