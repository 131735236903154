import styles from '../IpLight.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';

//External
import {  OutlinedInput, Card, CardContent, Typography, IconButton, Skeleton, Select, MenuItem, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//Interfaces
import { IpLightListData } from '../../interfaces/IPLightList';

//Services
import { WifiListByUsernameRequest, WifiListRequest } from '../../services/api';

//Components
import handleError from '../../utils/handleError';
import DateFormated from '../../components/ReturnDate/DateFormated';

enum Filter {
  designador = 'Designador' as any,
  // protocol_id = 'Protocolo' as any,
  serial_number = 'Serial' as any,
  login_tecnico = 'Login Tecnico' as any,
}

const SearchWifi = () => {
  const [ipLightList, setIpLightList] = useState<IpLightListData[] | null>(null);
  const [selectedQueryOption, setSelectedQueryOption] = useState<Filter>(Filter.designador);
  const [queryValue, setQueryValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  
  const { user } = useUserContext();

  const getIpLightList = async () => {
    if(user){
      const res = await WifiListByUsernameRequest(user.preferred_username);
      setIpLightList(res);
    }
  };

  useEffect(() => {
    getIpLightList();
  }, [user]);

  const handleQueryApiRequest = async () => {
    try {
      setLoading(true);
      setIpLightList(null);
      setError("");

      const res = await WifiListRequest(Filter[selectedQueryOption], queryValue);
      setIpLightList(res);
      setIpLightList(res);
    } catch (error) {
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };  

  return (
    <div className={styles.main}>
      <div className={styles.query_div}>
        <Select
          className={styles.select}
          autoWidth
          value={selectedQueryOption}
          onChange={(e) => setSelectedQueryOption(e.target.value as Filter)}
        >
          <MenuItem value={Filter.designador}>{Filter.designador}</MenuItem>
          {/* <MenuItem value={Filter.protocol_id}>{Filter.protocol_id}</MenuItem> */}
          <MenuItem value={Filter.serial_number}>{Filter.serial_number}</MenuItem>
          <MenuItem value={Filter.login_tecnico}>{Filter.login_tecnico}</MenuItem>
        </Select>
        <OutlinedInput
          className={styles.query_input}
          placeholder={`Busque por ${selectedQueryOption}`}
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
        />
        <Button
          className='button'
          variant="contained"
          color="primary"
          onClick={handleQueryApiRequest}
          disabled={loading}
        >
          Buscar
        </Button>
      </div>
      {ipLightList ? (
        ipLightList.map((data) => (
          <Card key={data.id} className={styles.card}>
            <CardContent className={styles.card_content}>
              <div className={styles.info}>
                <Typography sx={{ fontWeight: '600' }}>
                  Login Tecnico: <span>{data.login_tecnico}</span>
                </Typography>
                <Typography >
                  Modelo: <span> {data.modelo}</span>
                </Typography>
                <Typography >
                  <span>Designador:</span> {data.designador}
                </Typography>
                <Typography >
                  <span>Serial Number:</span> {data.serial_number}
                </Typography>
                <Typography >
                  <span>Velocidade:</span> {data.veloc_contratada} MBPS
                </Typography>
                {/* <StatusIcon status={data.approved} /> */}
                <Typography >
                  <DateFormated date={data.sent_time} />
                </Typography>
              </div>
            </CardContent>
            <div className={styles.details_button}>
              <IconButton component={Link} to={data.id}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </Card>
        ))
      ) : (
        <Skeleton variant="rectangular" className={styles.card_skeleton} />
      )}
      
    </div >
  );
};

export default SearchWifi;