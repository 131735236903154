//CSS
import styles from "./Home.module.css";
import "react-multi-carousel/lib/styles.css";

//Assets
import ipLight from "../../assets/images/ip_light2.png";
import wifi from "../../assets/svgs/wifi.svg"
import graphic from "../../assets/svgs/graphic.svg"
import vrGlasses from "../../assets/images/vr_glasses.png"

//External
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

type CardType = {
  title: string;
  description: string;
  image: string;
  url: string;
  isNew: boolean;
};

// const cardInfo: CardType[] = [
//   {
//     title: "",
//     description: "Fique por dentro das boas práticas de instalação dos nossos produtos.",
//     image: ipLight,
//     url: "/",
//     isNew: false
//   },
//   {
//     title: "",
//     description: "Saiba tudo sobre as novas tecnologias.",
//     image: vrGlasses,
//     url: "/",
//     isNew: true
//   },
//   {
//     title: "",
//     description: "Obtenha insights valiosos para aprimorar a expriência do cliente",
//     image: bulb,
//     url: "/",
//     isNew: false
//   },
// ]

const cardData: CardType[] = [
  {
    title: "CERTIFICADO BANDA LARGA",
    description: "Consulte os certificados Banda Larga com simplicidade.",
    image: ipLight,
    url: "/banda-larga/internet",
    isNew: false
  },
  {
    title: "CERTIFICADO WIFI",
    description: "Consulte os certificados Wi-Fi com simplicidade.",
    image: wifi,
    url: "/wifi",
    isNew: false
  },
  {
    title: "HOME ADVANCED CONNECTIVITY",
    description: "Explore o máximo da tecnologia unindo o Magic Tool e HAC.",
    image: vrGlasses,
    url: "/hac-search",
    isNew: false
  },
  {
    title: "HAC DASHBOARD (Em breve)",
    description: "Veja todas as informações dos dispositivos do HAC na nossa DashBoard",
    image: graphic,
    url: "/",
    isNew: true
  },
];

const Header = () => (
  <div className={styles.header}>
    <h1 className={styles.header_title}>Aproveite o melhor do MagicTool.</h1>
    {/* <h2 className={styles.header_subtitle}>Agora para clientes B2C.</h2> */}
  </div>
);

const Subtitle = () => (
  <div className={styles.new_title}>
    <h1 className={styles.header_title}>Com o Magic Tool é diferente.<span className={styles.new_subtitle}>Ainda mais motivos para usar o aplicativo.</span></h1>
  </div>
)

const CardItem = ({ item }: { item: CardType; }) => (
  <Card className={(item.isNew ? styles.new_card : styles.card)}>
    <CardActionArea component={Link} to={item.url}>
      <CardContent>
        <Typography className={styles.title}>{item.title}</Typography>
        <Typography variant="h5" className={styles.description}>{item.description}</Typography>
      </CardContent>
      <CardMedia component="img" alt={item.title} src={item.image} className={styles.icons} />
    </CardActionArea>
  </Card>
);

const Home = () => {

  return (
    <div>
      <Header />
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className={styles.carousel}
        containerClass="container"
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 2,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {cardData.map((item, index) => (
          <CardItem key={index} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default Home;
