import { useState, useEffect } from 'react';

import styles from "./Interface.module.css";

import { Card, Typography, CardContent } from "@mui/material";

import { CircleGauge, CirclePower } from 'lucide-react';

import { InterfacesHguSvg } from '../../../../../assets/cpes/InterfacesHguSvg';

import DeviceInfo from './DeviceInfo';

import { MapDevices } from '../../../../../services/apiHac';
import { Button } from '@mui/material';
import { DhcpTable } from '../../../../../interfaces/DeviceMap';
import { Lan } from '../../../../../interfaces/Hac';

interface Props {
  devicesMap: DhcpTable[],
  lan: Lan | undefined,
}


const Interface = ({ devicesMap, lan }: Props) => {

  const [index, setIndex] = useState(1);
  const [eth1, setEth1] = useState<DhcpTable[]>();
  const [eth2, setEth2] = useState<DhcpTable[]>();
  const [eth3, setEth3] = useState<DhcpTable[]>();
  const [eth4, setEth4] = useState<DhcpTable[]>();

  const setCertificado = (devices: DhcpTable[]) => {
    let port1: DhcpTable[] = [];
    let port2: DhcpTable[] = [];
    let port3: DhcpTable[] = [];
    let port4: DhcpTable[] = [];
    devices.map((item) => {
      if (item.ethernet_port === "eth1") {
        port1.push(item);
      } else if (item.ethernet_port === "eth2") {
        port2.push(item);
      } else if (item.ethernet_port === "eth3") {
        port3.push(item);
      } else if (item.ethernet_port === "eth4") {
        port4.push(item);
      }
    });

    setEth1(port1);
    setEth2(port2);
    setEth3(port3);
    setEth4(port4);
  };

  useEffect(() => {
    if (devicesMap) {
      setCertificado(devicesMap);
    }
  }, [devicesMap]);

  return (
    <Card variant="outlined" sx={{ borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92a" }}>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <span>
          <Typography variant='h4' className={styles.title} sx={{ fontWeight: "bold", textAlign: 'center' }}>Ethernet</Typography>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 4px" }}>
            {index === 1 ?
              <Button variant={'contained'} className='button' disabled onClick={() => setIndex(index - 1)}>Anterior</Button>
              :
              <Button variant={'contained'} className='button' onClick={() => setIndex(index - 1)}>Anterior</Button>
            }
            {
              index === 4 ?
                <Button variant={'contained'} className='button' disabled onClick={() => setIndex(index + 1)}>Proxima</Button>
                :
                <Button variant={'contained'} className='button' onClick={() => setIndex(index + 1)}>Próxima</Button>
            }
          </div>
        </span>
        <section className={styles.sectionInfo} >
          <div className={styles.hguInterface}>
            <InterfacesHguSvg index={index} />
          </div>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant='h6' sx={{ fontWeight: "bold" }}>ETH{index}</Typography>
            <Typography variant='h6' sx={{ display: "flex", gap: ".5em", alignItems: "center" }} className={styles.speedInterface}>
              <span style={{ marginTop: "2px" }}><CircleGauge size={28} /></span>
              <span className={styles.infoSpeed}>
                <p className={styles.interface}>Velocidade Interface</p>
                <p className={styles.speed}>
                  {index === 1 && lan?.eth1_bandwidth !== "" ? <>{lan?.eth1_bandwidth}</> :
                    index === 2 && lan?.eth2_bandwidth !== "" ? <>{lan?.eth2_bandwidth}</> :
                      index === 3 && lan?.eth3_bandwidth !== "" ? <>{lan?.eth3_bandwidth}</> :
                        index === 4 && lan?.eth4_bandwidth !== "" ? <>{lan?.eth4_bandwidth}</> :
                          <>---</>
                  }
                </p>
              </span>
            </Typography>
            <Typography className={styles.statusInterface}>
              {
                index === 1 && lan?.eth1_status ? <>
                  <span><CirclePower size={28} color='#2D9B1B' /></span>
                  <p>Interface UP</p>
                </> :
                  index === 2 && lan?.eth2_status ? <>
                    <span><CirclePower size={28} color='#2D9B1B' /></span>
                    <p>Interface UP</p>
                  </> :
                    index === 3 && lan?.eth3_status ? <>
                      <span><CirclePower size={28} color='#2D9B1B' /></span>
                      <p>Interface UP</p>
                    </> :
                      index === 4 && lan?.eth4_status ? <>
                        <span><CirclePower size={28} color='#2D9B1B' /></span>
                        <p>Interface UP</p>
                      </> :
                        <>
                          <span><CirclePower size={28} color='red' /></span>
                          <p>Interface Down</p>
                        </>}
            </Typography>
          </div>
          <hr style={{ width: "100%", margin: 0, marginTop: "-2px" }} />
        </section>
        {eth1 && index === 1
          ? <DeviceInfo devices={eth1} />
          : eth2 && index === 2
            ? <DeviceInfo devices={eth2} />
            : eth3 && index === 3
              ? <DeviceInfo devices={eth3} />
              : eth4 && index === 4
                ? <DeviceInfo devices={eth4} />
                : <></>
        }
      </CardContent>
    </Card>
  );
};

export default Interface;