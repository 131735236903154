import React from 'react'

const ServerVoIP = () => {
  return (
    <svg width="45" height="50" viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 33H9.02" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37 1H5C2.79086 1 1 2.79086 1 5V13C1 15.2091 2.79086 17 5 17H37C39.2091 17 41 15.2091 41 13V5C41 2.79086 39.2091 1 37 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37 25H5C2.79086 25 1 26.7909 1 29V37C1 39.2091 2.79086 41 5 41H37C39.2091 41 41 39.2091 41 37V29C41 26.7909 39.2091 25 37 25Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 9H9.02" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.8881 39.9201V42.9201C64.8892 43.1986 64.8322 43.4743 64.7206 43.7294C64.6091 43.9846 64.4454 44.2137 64.2402 44.402C64.035 44.5902 63.7927 44.7336 63.5289 44.8228C63.265 44.912 62.9855 44.9452 62.7081 44.9201C59.631 44.5857 56.6751 43.5342 54.0781 41.8501C51.6619 40.3148 49.6135 38.2663 48.0781 35.8501C46.3881 33.2413 45.3364 30.2711 45.0081 27.1801C44.9831 26.9036 45.016 26.6249 45.1046 26.3617C45.1932 26.0986 45.3357 25.8568 45.5229 25.6517C45.7101 25.4466 45.9379 25.2828 46.1919 25.1706C46.4459 25.0584 46.7205 25.0004 46.9981 25.0001H49.9981C50.4834 24.9953 50.9539 25.1672 51.3219 25.4836C51.6898 25.8001 51.9302 26.2395 51.9981 26.7201C52.1247 27.6802 52.3596 28.6228 52.6981 29.5301C52.8327 29.888 52.8618 30.277 52.782 30.651C52.7023 31.0249 52.517 31.3682 52.2481 31.6401L50.9781 32.9101C52.4017 35.4136 54.4746 37.4865 56.9781 38.9101L58.2481 37.6401C58.52 37.3712 58.8633 37.1859 59.2372 37.1062C59.6112 37.0264 60.0002 37.0556 60.3581 37.1901C61.2654 37.5286 62.2081 37.7635 63.1681 37.8901C63.6539 37.9586 64.0975 38.2033 64.4146 38.5776C64.7318 38.9519 64.9003 39.4297 64.8881 39.9201Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default ServerVoIP