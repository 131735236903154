
import DateFormated from '../../../../../components/ReturnDate/DateFormated';
import { DadosAtividade } from '../../../../../interfaces/Hac';

import styles from './Aside.module.css';

import image from "../../../../../assets/cpes/1_geral.png";

import imageHGU6 from "../../../../../assets/cpes/Hgu6-Frente.png";

import { Card, Typography, CardContent } from "@mui/material";
import { Divider } from '@mui/material';

import { Box } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';


type Props = {
  info: DadosAtividade | undefined,
  ipv4: string | undefined;
};



const HguInfo = ({ info, ipv4 }: Props) => {

  const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6"];

  return (
    <Card variant="outlined" sx={{ borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92a", minHeight: "25.3em" }}>
      <CardContent className={styles.asideContent} style={{ paddingBottom: '10px' }} sx={{ padding: "5px 10px" }}>
        {info && <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ fontWeight: 500, font: "regular", margin: 0 }} component="p">{info.connection_state}</Typography>
            <Typography className={styles.dateExec} component="p"><DateFormated dateGmt={3} date={info.sent_time} /></Typography>
          </div>

          <Divider />
          {wifi6List.includes(info.modelo) 
            ? <>
              <Typography className={styles.hguImage} component='div'>
                <img src={imageHGU6} alt='HGU Imagem' style={{ width: "44%" }} />
                <p>{info.device_class}</p>
              </Typography>
            </>
            : <Typography className={styles.hguImage} component='div'>
              <img src={image} alt='HGU Imagem' style={{ width: "44%" }} />
              <p>{info.device_class}</p>
            </Typography>
          }
          <Divider />
          <Typography className={styles.line}>
          <Box display={'flex'} width={'100%'}>
            <div>
                <p className={styles.h1Atividade}>{info.modelo_alias}:</p>
                <p className={styles.labelAtividade}>{info.modelo}</p>
            </div>
            <Divider orientation='vertical' flexItem sx={{ margin: '0 1em' }} />
            <div>
              <p className={styles.h1Atividade}>{info.designador_alias}:</p>
              <p className={styles.labelAtividade}>{typeof info.designador === "object" ? "No Data" : typeof info.designador === null ? "No Data" : info.designador === "{}" ? "No Data" 
                : info.designador}</p>
            </div>
          </Box>
          </Typography>
          <Divider />
          <Typography className={styles.line}>
            <Box display={'flex'} width={'100%'}>
              <div>
                <p className={styles.h1Atividade}>IPv4:</p>
                <p className={styles.labelAtividade}>{ipv4}</p>
              </div>
              <Divider orientation='vertical' flexItem sx={{ margin: '0 1em' }} />
              <div>
                <p className={styles.h1Atividade}>{info.serial_number_alias}:</p>
                <p className={styles.labelAtividade}>{info.serial_number}</p>
              </div>
            </Box>
          </Typography>
          <Divider />
          <Typography className={styles.line}>
            <Box display={'flex'} width={'100%'}>
              <div>
                <p className={styles.h1Atividade}>{info.firmware_alias}:</p>
                <p className={styles.labelAtividade}>{info.firmware}</p>
              </div>
            </Box>
          </Typography>
          <Divider />
          <Typography className={styles.line}>
            <div>
              <p className={styles.h1Atividade}>{info.device_id_alias}:</p>
              <p className={styles.labelAtividade}>{info.device_id}</p>
            </div>
          </Typography>
          <Divider />
          <Typography className={styles.line}>
            <Box display={'flex'} width={'100%'}>
              <div>
                <p className={styles.h1Atividade}>{info.iothub_alias}:</p>
                <p className={styles.labelAtividade}>{info.iothub}</p>
              </div>
            </Box>  
          <Divider />
            <div>
              <p className={styles.h1Atividade}>{info.last_activity_time_alias}:</p>
              <p className={styles.labelAtividade}><DateFormated date={info.last_activity_time} /></p>
            </div>
          </Typography>
        </>}
      </CardContent>
    </Card>
  );
};


export default HguInfo;