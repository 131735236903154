import styles from './App.module.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
// Context
// Hooks
// Utils
import { Roles } from './utils/EnumRoles';
// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
// Pages
import Home from './pages/Home/Home';
import Suporte from './pages/Suporte';
import ApprovUser from './pages/ApproveUser/ApprovUser';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/NotFound';
import Hac from './pages/Hac/Hac';
import NewWifi from './pages/WiFi/NewWifi';
import Internet from './pages/Internet/Internet';

import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from '@mui/material';
import Search from './pages/Search/Search';
import SearchWifi from './pages/Search/SearchWifi';
import SearchHac from './pages/Search/SearchHac';

function App() {

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      }
    }
  }, [initialized]);

  if (!initialized || !keycloak.authenticated) {
    return (
      <div className={styles.app_loading}>
        <h1>MagicTool</h1>
        <CircularProgress />
      </div>
    );
  }


  return (
    <BrowserRouter>
      <Navbar />
      <div className={styles.App}>
        <Routes>
          {/* Public Routes */}
          <Route path='/unauthorized' element={<Unauthorized />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoute allowedRoles={Object.values(Roles)} />}>
            <Route path='/' element={<Home />} />
            <Route path='/banda-larga/internet' element={<Search />} />
            <Route path='/banda-larga/internet/:id' element={<Internet />} />
            <Route path='/wifi' element={<SearchWifi />} />
            <Route path='/wifi/:id' element={<NewWifi />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={[Roles.admin, Roles.avancado, Roles.suporte]} />}>
            <Route path='/suporte' element={<Suporte />}  >
              <Route path='approv-users' element={<ApprovUser />} />
            </Route>
            
            <Route path='/hac-search' element={<SearchHac />} />
            <Route path='/hac-search/hac/:id' element={<Hac />} />
          </Route>

          {/* Not Found */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
