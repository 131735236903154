import styles from '../IpLight.module.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
//External
import { OutlinedInput, Button, Card, CardContent, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

//Services
import { HacRequest } from '../../services/apiHac';
import handleError from '../../utils/handleError';
import { IpLightListData } from '../../interfaces/IPLightList';
import DateFormated from '../../components/ReturnDate/DateFormated';
//Components

type Props = {};

const SearchHac = (props: Props) => {
  
  const [ipLightList, setIpLightList] = useState<IpLightListData | null>(null);
  const [queryValue, setQueryValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleQueryApiRequest = async () => {
    try {
      setLoading(true);
      setError("");
      const res = await HacRequest(queryValue)
      const list: IpLightListData = {
        id: res.dadosAtividade.ordem_id_eta,
        approved: false,
        serial_number: res.dadosAtividade.serial_number,
        modelo: res.dadosAtividade.modelo,
        designador: res.dadosAtividade.designador,
        sent_time: res.dadosAtividade.sent_time,
        veloc_contratada: '',
        login_tecnico: '',
        firmware: res.dadosAtividade.firmware,
      }
      setIpLightList(list)
    } catch (error) {
      if(queryValue.length <= 11){
        setError("Valor invalido, sua pesquisa deve ter no minimo 12 caracteres")
        return
      }
      handleError({ error, setError });
    } finally {
      setLoading(false);
    }
  };  

  return (
    <div className={styles.main}>
      <div className={styles.query_div}>
        <OutlinedInput
          className={styles.query_input}
          placeholder={`Busque por Designador ou Numero do Serial`}
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
        />
        <Button
          className='button'
          variant="contained"
          color="primary"
          onClick={handleQueryApiRequest}
          disabled={loading}
        >
          Buscar
        </Button>
      </div>
      {!loading ? <>
        {ipLightList &&
            <Card key={ipLightList.id} className={styles.card}>
              <CardContent className={styles.card_content}>
                <div className={styles.ipLightList}>
                  <Typography sx={{ fontWeight: '600' }}>
                    Firmware: <span>{ipLightList.firmware}</span>
                  </Typography>
                  <Typography >
                    Modelo: <span> {ipLightList.modelo}</span>
                  </Typography>
                  <Typography >
                    <span>Designador:</span> {typeof ipLightList.designador === "object" ? "No Data" : typeof ipLightList.designador === null ? "No Data" : ipLightList.designador === "{}" ? "No Data" 
                    : ipLightList.designador}
                  </Typography>
                  <Typography >
                    <span>Serial Number:</span> {ipLightList.serial_number}
                  </Typography>
                  <Typography >
                    <DateFormated date={ipLightList.sent_time} />
                  </Typography>
                </div>
              </CardContent>
              <div className={styles.details_button}>
                <IconButton component={Link} to={`hac/${queryValue}`}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </Card>
            }
          {error && 
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          } 
        </>: <>
          <Box sx={{ display: 'flex', marginTop: "1em" }}>
            <CircularProgress />
          </Box>
        </>}
    </div >
  );
};

export default SearchHac;