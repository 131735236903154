import * as React from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { HacInfoData } from "../../../interfaces/Hac"

import styles from "./PageContainer.module.css";

import Certification from './Certification/Certification';

import DevicesMap from './Content/DevicesMap/DeviceMap';

import { DeviceMap } from '../../../interfaces/DeviceMap';

import ContainerCobertura from './Content/Cobertura/ContainerCobertura';
import Alert from './Content/Alerts/Alert';
import { AlertsInterfaceData } from '../../../interfaces/AlertsInterface';

import { AliasCobertura } from '../../../interfaces/ScoreInterface';

import { AlertsRequest, MapDevices, InterfaceCoberturaRequest, StationsCoberturaRequest } from '../../../services/apiHac';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className={styles.nav}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: "10px 0" }}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



interface Props {
  certification: HacInfoData
  deviceMapped: DeviceMap | undefined
  id: string
}


export default function FullWidthTabs({certification, id, deviceMapped }: Props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [coberturaInterface, setCoberturaInterface] = React.useState<AliasCobertura>()
  const [coberturaStation, setCoberturaStation] = React.useState<AliasCobertura>()
  const [alerts, setAlerts] = React.useState<AlertsInterfaceData>()
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const handleChangeIndex = (index: number) => {
    setValue(index);
  }

  const requestsCobertura = async(id:string) =>{
    const resCoberturaInterface = await InterfaceCoberturaRequest(id)
    setCoberturaInterface(resCoberturaInterface)

    const resCoberturaScore = await StationsCoberturaRequest(id)
    setCoberturaStation(resCoberturaScore)
  }

  const Requests = async(id: string) =>{

    const resAlerts = await AlertsRequest(id);
    const alertData: AlertsInterfaceData = {
      alertas: resAlerts,
      date: new Date().toISOString()
    }
    setAlerts(alertData)
  }

  React.useEffect(() => {
    if(id){
      Requests(id)
      requestsCobertura(id)
    }
  }, [id])
  
  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" sx={{ backgroundColor: "#fff", borderRadius: '10px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          // variant="fullWidth"
          sx={{ backgroundColor: "Transparent", color: "#000" }}
          className={styles.tabs}
        >
          <Tab label="Cert Banda Larga" {...a11yProps(0)} />
          <Tab label="Funções"  disabled {...a11yProps(1)} />
          <Tab label="Mapa Dispositivos" {...a11yProps(2)}  />
          <Tab label="Cobertura WiFi" {...a11yProps(3)} />
          <Tab label="Alertas" {...a11yProps(4)} />
          <Tab label="Recomendações" disabled {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Certification cert={certification} id={id} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Mapa Dispositivos
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        {
            deviceMapped ? <DevicesMap devices={deviceMapped} />
            :
            <>
              <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {coberturaInterface || coberturaStation ?
            <ContainerCobertura id={id} scoreInterface={coberturaInterface} scoreStation={coberturaStation} />
            : <>
              <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          {
            alerts ? <Alert alertsInfo={alerts} id={id} />
            :
            <>
              <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          Recomedações
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}