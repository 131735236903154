import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ApiError from "../utils/ApiError";
import keycloak from "../config/keycloak";
import { HacInfoData } from "../interfaces/Hac";
import { DeviceMap } from "../interfaces/DeviceMap";
import { AlertsInterface } from "../interfaces/AlertsInterface";
import { AliasCobertura } from "../interfaces/ScoreInterface";

const BASE_URL_HAC: string = process.env.REACT_APP_API_BASE_URL_HAC ? process.env.REACT_APP_API_BASE_URL_HAC : 'http://127.0.0.1:8000/api/v1/web' ;


// Helper function to make HTTP requests
async function request(url: string, method: string = 'GET', data: any = null, headers: any = {}): Promise<any> {
  const access_token = keycloak.token;
  const authHeaders = access_token ? { Authorization: `Bearer ${access_token}` } : {};

  const config: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    },
    url:`${BASE_URL_HAC}${url}` ,
    data: data ? JSON.stringify(data) : undefined,
};
  try {
    const response = await axios(config);

    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new ApiError({ code: error.code, message: error.message, cause: error.cause, responseData: error.response?.data });
    }
    throw new Error(`API Error: ${error.message}`);
  }
}


export async function HacRequest(value: string): Promise<HacInfoData> {
  return await request(`/devices/${value}/certifications`, 'GET');
}
  

export async function MapDevices(value: string): Promise<DeviceMap> {
  return await request(`/devices/${value}/direct-method/show-map-info`, 'GET');
}

export async function AlertsRequest(value: string): Promise<AlertsInterface[]> {
  return await request(`/devices/${value}/alerts/coverage`, 'GET');
}

export async function InterfaceCoberturaRequest(value: string): Promise<AliasCobertura> {
  return await request(`/devices/${value}/score/coverage/interfaces`, 'GET');
}
  

export async function StationsCoberturaRequest(value: string): Promise<AliasCobertura> {
  return await request(`/devices/${value}/score/coverage/stations`, 'GET');
}
  
