import { useState, useEffect, useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';

import { HacInfoData } from "../../interfaces/Hac";

import styles from "./Hac.module.css";

import { HacRequest, MapDevices} from "../../services/apiHac";

import { useParams } from 'react-router-dom';

import FullWidthTabs from './PageContainer/PageContainer';

import HguInfo from "./PageContainer/Aside/HguInfo/HguInfo";
import Interface from './PageContainer/Aside/InterfaceInfo/Interface';
import { DeviceMap } from '../../interfaces/DeviceMap';


const Hack = () => {

  const [data, setData] = useState<HacInfoData | undefined>();
  const [deviceMapped, setDeviceMapped] = useState<DeviceMap>();
  const [load, setLoad] = useState(false);

  const { id } = useParams();

  const setCertificado = async (id: string) => {
    setLoad(true);
    const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6",];

    const res = await HacRequest(id);
    if (wifi6List.includes(res.dadosAtividade.modelo)) {
      delete res.summary_status.cert_wifi_5G_BS;
      delete res.cert_wifi_5G_BS;
    }
    setData(res);
    setLoad(false);

    const resDevices = await MapDevices(id);
    setDeviceMapped(resDevices);

  };

  useEffect(() => {
    if (id) {
      setCertificado(id);
    }
  }, [id]);

  return (
    <div className={styles.main}>
      {!load ? <>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {data && id && 
            <FullWidthTabs 
              certification={data} 
              id={id}
              deviceMapped={deviceMapped}
            />}
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HguInfo ipv4={data?.cert_wan.dns_ipv4[0]}
                  info={data?.dadosAtividade}
                />
              </Grid>
              <Grid item xs={12}>
                {deviceMapped &&
                  <Interface devicesMap={deviceMapped.dhcp_table} lan={data?.cert_lan} />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </> : <>
        <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </>}
    </div>
  );
};

export default Hack;