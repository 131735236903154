import { useState, useEffect } from "react"

import { Typography } from "@mui/material"

import styles from "./Interface.module.css"
import { WifiTethering } from "@mui/icons-material"
import { AudioLines, MapPin } from "lucide-react"

import { DhcpTable } from "../../../../../interfaces/DeviceMap"
import TableDeviceInfo from "./TableDeviceInfo"


const DeviceInfo = ({devices}: {devices: DhcpTable[]}) => {
  
  return (
    <section>
      {devices.length > 0 ? 
      <>
        <Typography variant='h5' className={styles.title} sx={{fontWeight: "bold", textAlign: "center"}}>Dispositivos na interface</Typography>
          <div className={styles.device}>
            <div className={styles.deviceTop}>
              <span className={styles.bp}><WifiTethering sx={{fontSize: 35}} />
                <p>
                  {devices[0].host_name != "" ? <>{devices[0].host_name}</> : <>devices[0].mac_addr</>}
                </p>
              </span>
              <span className={styles.dbm}>
                <AudioLines size={28} />
                <p> --- </p>
              </span> 
            </div>
            <hr />
            <div className={styles.deviceBottom}>
              <span className={styles.ipA}>
                <MapPin size={28} />
                <span>
                  <strong>IP Adress</strong>
                  <p><>{devices[0].ip_addr}</></p>
                </span>
              </span>
              {devices.length > 1 && <TableDeviceInfo quantityDevices={devices.length} devices={devices} />}
            </div>
          </div>
      </> : 
      <p style={{textAlign: "center"}}>
        Sem informações
      </p> }
    </section>
  )
}

export default DeviceInfo