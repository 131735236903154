// React
import React, { useEffect, useState } from 'react'

//Styles
import styles from "./Container.module.css"
import stylesAlert from "../Alerts/Alert.module.css"

// Componentes Material UI
import { Card, Typography, CardContent, SelectChangeEvent, Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

// Lucide
import {ArrowDown, ArrowUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, RefreshCcw, Search} from "lucide-react"

// Componentes
import AreaSparkLine  from "./Cobertura"
import { AliasCobertura, Cobertura } from '../../../../../interfaces/ScoreInterface';

//  formatação da data
import { parseISO } from 'date-fns';

import { InterfaceCoberturaRequest, StationsCoberturaRequest } from '../../../../../services/apiHac';

interface Props {
  scoreStation: AliasCobertura | undefined
  scoreInterface: AliasCobertura | undefined
  id: string
}

const ContainerCobertura = ({scoreStation, scoreInterface, id}: Props) => {

  const [interfaceView, setInterfaceView] = useState("2.4")
  const [filter, setFilter] = useState("")
  const [page, setPage] = useState(1)
  const [scoreStation2G, setScoreStation2G] = useState<Cobertura[]>()
  const [scoreStation5G, setScoreStation5G] = useState<Cobertura[]>()
  const [scoreStationData, setScoreStationData] = useState(scoreStation)
  const [scoreInterfaceData, setInterfaceData] = useState(scoreInterface)
  const [itensPerPage, setItensPerPage] = useState(5)
  const [order, setOrder] = useState("Date desc")
  const [iconDatePosition, setIconDatePosition] = useState("Date desc")
  const [iconScorePosition, setIconScorePosition] = useState("Score desc")
  const [week2G, setWeek2G] = useState<string[]>([])
  const [week5G, setWeek5G] = useState<string[]>([])
  const [score2G, setScore2G] = useState<number[]>([])
  const [score5G, setScore5G] = useState<number[]>([])
  const [load, setLoading] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    setInterfaceView(event.target.value as string);
  };

  const filteredData2G = scoreStation2G && scoreStation2G.filter(query => query.vendor?.includes(filter)
    || query.score_date.includes(filter)
    || query.mac_address?.includes(filter)
  )

  const filteredData5G = scoreStation5G && scoreStation5G.filter(query => query.vendor?.includes(filter)
    || query.score_date.includes(filter)
    || query.mac_address?.includes(filter)
  )

  if(order === "Date desc"){
    filteredData2G?.sort((a, b) => new Date(parseISO(b.score_date)).getTime() - new Date(parseISO(a.score_date)).getTime())
    filteredData5G?.sort((a, b) => new Date(parseISO(b.score_date)).getTime() - new Date(parseISO(a.score_date)).getTime())
  }else if(order === "Date asc"){
    filteredData2G?.sort((a, b) => new Date(parseISO(a.score_date)).getTime() - new Date(parseISO(b.score_date)).getTime()) 
    filteredData5G?.sort((a, b) => new Date(parseISO(a.score_date)).getTime() - new Date(parseISO(b.score_date)).getTime()) 
  }else if(order === "Score asc"){
    filteredData2G?.sort((a, b) => a.weighted_average_score - b.weighted_average_score )
    filteredData5G?.sort((a, b) => a.weighted_average_score - b.weighted_average_score )
  }else if (order === "Score desc" ){
    filteredData2G?.sort((a, b) => b.weighted_average_score - a.weighted_average_score )
    filteredData5G?.sort((a, b) => b.weighted_average_score - a.weighted_average_score )
  }

  const totalPages = Math.ceil(scoreStation2G ? scoreStation2G.length / itensPerPage : 0)   
  const totalPages5G = Math.ceil(scoreStation5G ? scoreStation5G.length / itensPerPage : 0)   

  function goBackPage (){
    setPage(page - 1)
  }

  function goNextPag(){
    setPage(page + 1)
  }

  function goLastPage(){
    setPage(totalPages)
  }
  function goLastPage5(){
    setPage(totalPages5G)
  }

  function goFirstPage(){
    setPage(1)
  }

  const orderData = () => {
    scoreInterfaceData?.values.sort((a, b) => {
      const dateA = new Date(a.score_date)
      const dateB = new Date(b.score_date)

      return dateA.getTime() - dateB.getTime()
    })

    const result = {
      "2.4 GHz": {
        score_date: [] as string[],
        avarega_scores: [] as number[],
        scoreStation: [] as Cobertura[]
      },
      "5 GHz": {
        score_date: [] as string[],
        avarega_scores: [] as number[],
        scoreStation: [] as Cobertura[]
      }
    } 

    scoreInterfaceData?.values.forEach(item => {
      const [year, month, day] = item.score_date.split("-")
      const formattedDate = `${day}/${month}`
      if(item.frequency.includes("2.4")){
        result["2.4 GHz"].score_date.push(formattedDate)
        result["2.4 GHz"].avarega_scores.push(item.weighted_average_score)
      }else if(item.frequency.includes("5")){
        result["5 GHz"].score_date.push(formattedDate)
        result["5 GHz"].avarega_scores.push(item.weighted_average_score)
      }
    })

    scoreStationData?.values.forEach(item => {
      if(item.frequency.includes("2.4")){
        result["2.4 GHz"].scoreStation.push(item)
      }else if(item.frequency.includes("5")){
        result["5 GHz"].scoreStation.push(item)
      }
    })

    setWeek2G(result["2.4 GHz"].score_date)
    setWeek5G(result["5 GHz"].score_date)
    setScore2G(result["2.4 GHz"].avarega_scores)
    setScore5G(result["5 GHz"].avarega_scores)
    setScoreStation2G(result["2.4 GHz"].scoreStation)
    setScoreStation5G(result["5 GHz"].scoreStation)
  }

  const setScorePosition = (value: string) => {
    setOrder(value)
    setIconScorePosition(value)
  }

  const setDatePosition = (value: string) => {
    setOrder(value)
    setIconDatePosition(value)
  }

  const requestCobertura = async() => {
    const resCoberturaInterface = await InterfaceCoberturaRequest(id)
    setInterfaceData(resCoberturaInterface)
  }

  const requestStation = async() => {
    const resCoberturaScore = await StationsCoberturaRequest(id)
    setScoreStationData(resCoberturaScore)
  }

  const refreshData = async() => {
    setLoading(true)
    requestCobertura()
    requestStation()
    setLoading(false)
  }

  useEffect(() => {
    orderData()
  }, [scoreInterfaceData, scoreStationData])

  return (
    <div className={styles.container}>
      <Card  variant="outlined" sx={{borderRadius: "10px", boxShadow: "-9px 10px 15px #a9a9a92a"}}>
          <Box sx={{ minWidth: 140, padding: "10px 15px " }} className={styles.box}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Interface</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={interfaceView}
                label="Interface"
                onChange={handleChange}
              >
                <MenuItem value="2.4">Interface 2.4GHz</MenuItem>
                <MenuItem value="5">Interface 5GHz</MenuItem>
              </Select>
            </FormControl>
        </Box>
        {interfaceView === "2.4" &&
          <CardContent className={styles.card}>
            {load ?
              <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
             : <>
            <Typography component="h1">Cobertura 2.4GHz</Typography>
            <AreaSparkLine xData={week2G} data={score2G} color='#8D79F6' />
            <div className={styles.searchDiv}>
              <div className={styles.controledInput}>
                <TextField id="standard-basic" 
                  placeholder='Busque por informações especificas...'
                  variant="standard"
                  sx={{width: "100%"}}
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />
                <Search />
              </div>
              <Button variant="contained"
                className={styles.btn}
                sx={{backgroundColor: "transparent",
                    border: "1px solid #fff",  
                    color: "#fff", 
                    '&:hover' : {backgroundColor: "transparent",},
                }} 
                onClick={refreshData}
                startIcon={<RefreshCcw />}
                >
                  Atualizar
              </Button>
            </div>
            {filteredData2G && filteredData2G.length > 0 && 
              <>
                <div className={styles.containerTable}>
                  <table>
                    <thead className={styles.thead}>
                      <tr>
                        <th className={styles.thData}>
                          {scoreStationData?.alias.score_date} {iconDatePosition === "Date desc" ? 
                            <IconButton>
                              <ArrowDown color='#fff' onClick={() => setDatePosition("Date asc")} />
                            </IconButton>
                          : iconDatePosition === "Date asc" && 
                            <IconButton>
                              <ArrowUp color='#fff' onClick={() => setDatePosition("Date desc")} />
                            </IconButton>
                          }
                        </th>
                        <td>{scoreStationData?.alias.vendor}</td>
                        <td>{scoreStationData?.alias.mac_address}</td>
                        <td>{scoreStationData?.alias.total_count}</td>
                        <td className={styles.thData}>?
                          {scoreStationData?.alias.weighted_average_score} {iconScorePosition === "Score desc" ?
                            <IconButton>
                              <ArrowDown color='#fff' onClick={() => setScorePosition("Score asc")} />
                            </IconButton>
                            : iconScorePosition === "Score asc" && 
                            <IconButton>
                              <ArrowUp color='#fff' onClick={() => setScorePosition("Score desc")} />
                            </IconButton>
                          }
                        </td>
                      </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                    {filteredData2G && filteredData2G.slice((page - 1) * itensPerPage, page * itensPerPage).map((data, index) => (
                      <>
                        <tr key={index} className={index % 2 === 1 && styles.colored}>
                          <td>{data.score_date}</td>
                          <td>{data.vendor}</td>
                          <td>{data.mac_address}</td>
                          <td>{data.total_count}</td>
                          <td>{data.weighted_average_score}</td>
                        </tr>
                      </>
                    ))}
                    </tbody>
                    <tfoot>
                      <td colSpan={3} > 
                        Mostrando
                        <select style={{margin: "0 5px"}} value={itensPerPage} onChange={e => setItensPerPage(parseInt(e.target.value))}>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                        </select> 
                        linhas de {filteredData2G.length} itens
                      </td>
                      <td colSpan={2} style={{textAlign: "right"}}>
                        <div>
                          <span>Pagina {page} de {totalPages} </span>
                          <div>
                            <button className={stylesAlert.buttonPage} onClick={goFirstPage} disabled={page ===1}>
                              <ChevronsLeft />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goBackPage} disabled={page ===1}>
                              <ChevronLeft />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goNextPag} disabled={page === totalPages}>
                              <ChevronRight />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goLastPage} disabled={page === totalPages}>
                              <ChevronsRight />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                </div>
              </>
            }
          </>}
          </CardContent>
        }
        {interfaceView === "5" &&
          <CardContent className={styles.card}>
            {load ? <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                <CircularProgress />
              </Box>:
              <>
            <Typography component="h1">Cobertura 5GHz</Typography>
            <AreaSparkLine xData={week5G} data={score5G} color='#22CB7A' colorStroke='#17ab279e' />
            <div className={styles.searchDiv}>
              <div className={styles.controledInput}>
                <TextField id="standard-basic" 
                  placeholder='Busque por informações especificas...'
                  variant="standard"
                  sx={{width: "100%"}}
                  onChange={e => setFilter(e.target.value)}
                />
                <Search />
              </div>
              <Button variant="contained"
                className={styles.btn}
                sx={{backgroundColor: "transparent",
                
                    border: "1px solid #fff",  
                    color: "#fff", 
                    '&:hover' : {backgroundColor: "transparent",},
                    }} 
                startIcon={<RefreshCcw />}
                onClick={refreshData}
                >
                  Atualizar
              </Button>
            </div>
            {filteredData5G && filteredData5G.length > 0 && 
              <>
                <div className={styles.containerTable}>
                  <table>
                    <thead className={styles.thead}>
                      <tr className={styles.tr}>
                      <th className={styles.thData}>
                        {scoreStationData?.alias.score_date} {iconDatePosition === "Date desc" ? 
                          <IconButton>
                            <ArrowDown color='#fff' onClick={() => setDatePosition("Date asc")} />
                          </IconButton>
                          : iconDatePosition === "Date asc" && 
                          <IconButton>
                            <ArrowUp color='#fff' onClick={() => setDatePosition("Date desc")} />
                          </IconButton>
                        }
                        </th>
                        <td>{scoreStationData?.alias.vendor}</td>
                        <td>{scoreStationData?.alias.mac_address}</td>
                        <td>{scoreStationData?.alias.total_count}</td>
                        <td className={styles.thData}>
                          {scoreStationData?.alias.weighted_average_score} {iconScorePosition === "Score desc" ?
                            <IconButton>
                              <ArrowDown color='#fff' onClick={() => setScorePosition("Score asc")} />
                            </IconButton>
                            : iconScorePosition === "Score asc" && 
                            <IconButton>
                              <ArrowUp color='#fff' onClick={() => setScorePosition("Score desc")} />
                            </IconButton>
                          }
                        </td>
                      </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                    {filteredData5G.slice((page - 1) * itensPerPage, page * itensPerPage).map((data, index) => (
                      <>
                        <tr key={index} className={index % 2 === 1 && styles.colored5}>
                          <td>{data.score_date}</td>
                          <td>{data.vendor}</td>
                          <td>{data.mac_address}</td>
                          <td>{data.total_count}</td>
                          <td>{data.weighted_average_score}</td>
                        </tr>
                      </>
                    ))}
                    </tbody>
                    <tfoot>
                      <td colSpan={3} > 
                        Mostrando
                        <select style={{margin: "0 5px"}} value={itensPerPage} onChange={e => setItensPerPage(parseInt(e.target.value))}>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                        </select> 
                        linhas de {filteredData5G.length} itens
                      </td>
                      <td colSpan={2} style={{textAlign: "right"}}>
                        <div>
                          <span>Pagina {page} de {totalPages5G} </span>
                          <div>
                            <button className={stylesAlert.buttonPage} onClick={goFirstPage} disabled={page ===1}>
                              <ChevronsLeft />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goBackPage} disabled={page ===1}>
                              <ChevronLeft />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goNextPag} disabled={page === totalPages5G}>
                              <ChevronRight />
                            </button>
                            <button className={stylesAlert.buttonPage} onClick={goLastPage5} disabled={page === totalPages5G}>
                              <ChevronsRight />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                </div>
              </>
            }
            </>}
          </CardContent>
        }
      </Card>
    </div>
  )
}

export default ContainerCobertura