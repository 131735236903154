import { useState } from "react";

import styles from "./Certification.module.css";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { HacInfoData } from "../../../../interfaces/Hac";

import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import DonnutChart from '../../../../components/GraphicsChart/DonnutChart';

import telephone from "../../../../assets/images/telephone.png";
import wifi from "../../../../assets/images/wifi.png";
import display from "../../../../assets/images/display.png";
import negative from "../../../../assets/images/negative.png";
import checked from "../../../../assets/images/checked.png";

import { Globe, Server, Router, RefreshCcw, CircleCheck, CircleX, CircleAlert } from 'lucide-react';
import ServerVoIP from "../Content/ServerVoIP";
import Carousel from "react-multi-carousel";
import { ListItemIcon, ListItemText } from "@mui/material";
import AlertDialogSlide from "../../Details/ViewMore";

import { HacRequest } from "../../../../services/apiHac";

interface CertificationProps {
  cert: HacInfoData,
  id: string;
}

const Certification = ({ cert, id }: CertificationProps) => {

  const [certification, setCertification] = useState<HacInfoData>(cert);
  const [load, setLoad] = useState(false);

  const porcentagem = (valor_base: number, percent: number): number => {
    const resultado = (percent / valor_base) * 100;

    return resultado;
  };

  // so é chamado quando clica em atualizar certificado
  const handleCert = async () => {
    setLoad(true);
    const res = await HacRequest(id);

    const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6",];
    if (wifi6List.includes(res.dadosAtividade.modelo)){
      delete res.summary_status.cert_wifi_5G_BS;
      delete res.cert_wifi_5G_BS;
    }

    setCertification(res);
    setLoad(false);
  };

  let total: number = 0;

  let succes_percent: number = 0;
  let warnning_percent: number = 0;
  let failed_percent: number = 0;

  if (certification) {
    total = (certification.dadosAtividade.warning + certification.dadosAtividade.failed + certification.dadosAtividade.passed);
    succes_percent = porcentagem(total, certification.dadosAtividade.passed);
    warnning_percent = porcentagem(total, certification.dadosAtividade.warning);
    failed_percent = porcentagem(total, certification.dadosAtividade.failed);
  }

  return (
    <div>
      {!load ? <>
        <Card className={styles.charts} variant="outlined" >
          <CardContent sx={{ textAlign: "center", marginTop: "-5px" }} className={styles.graphicContainer}>
            <DonnutChart name='Aprovados' percent={succes_percent} test={certification.dadosAtividade.passed} />
            <DonnutChart name='Reprovados' percent={failed_percent} test={certification.dadosAtividade.failed} />
            <Card className={styles.scoreCert} variant="outlined" >
              <CardContent className={styles.scoreContent} sx={{ textAlign: "center", marginTop: "-5px" }} >
                <Typography variant='h5' className={styles.scoreDetails}>
                  <p style={{ padding: "5px", fontWeight: "Bold", fontSize: '2rem' }}>Score da certificação</p>
                </Typography>
                <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "4em" }}>{certification.summary_status_score}</Typography>
                <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid #fff",
                      margin: '0 auto',
                      color: "#fff",
                      '&:hover': { backgroundColor: "transparent", },

                    }}
                    startIcon={<RefreshCcw />}
                    onClick={handleCert}>
                    Atualizar certificado
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
        <section className={styles.services}>
          <Card className={styles.topologia} variant="outlined" sx={{ width: "50%", borderRadius: "10px", minWidth: 300, boxShadow: "-9px 10px 15px #a9a9a92b" }}>
            <Typography variant='h5' sx={{ textAlign: "center", padding: "15px 0 0 0", fontWeight: "bold" }}>Topologia</Typography>
            <CardContent className={styles.topologyContent}>
              <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                <Globe size={40} strokeWidth={1} className={styles.svgTopology} />
                <p style={{ margin: 0, fontWeight: 500 }} className={styles.titleIcon}>Internet</p>
              </div>

              <div className={styles.topologyDiv}>
                {certification.dadosAtividade.connection_state === "Connected" ? <p className={styles.auth}>Autenticado</p> :
                  <p className={styles.no_auth}>Não Autenticado</p>}
                <hr />
                <p className={styles.bdm} style={{ backgroundColor: "transparent", color: "black" }}>{certification.cert_wan.dns_ipv4[0]}</p>
              </div>

              <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                <Server size={40} strokeWidth={1} className={styles.svgTopology} />
                <p style={{ margin: 0, fontWeight: 500 }} className={styles.titleIcon}>OLT</p>
              </div>

              <div className={styles.topologyDiv} style={{ marginTop: 0 }}>
                {certification.cert_wan.hgu_rx && certification.cert_wan.hgu_rx >= -25 ? <div>
                  <p className={styles.bdm} style={{ backgroundColor: "rgba(66, 245, 66, 0.342)", color: "green" }}>RX: {certification.cert_wan.hgu_rx.toFixed(2)} dBm</p>
                </div> : certification.cert_wan.hgu_rx && certification.cert_wan.hgu_rx <= -26 && certification.cert_wan.hgu_rx >= -30 ? <div>
                  <p className={styles.bdm}>RX: {certification.cert_wan.hgu_rx} dBm</p>
                </div> :
                  <div>
                    <p className={styles.bdm} style={{ backgroundColor: "#ff00004d", color: "#c91313" }}>RX: {certification.cert_wan.hgu_rx.toFixed(2)} dBm</p>
                  </div>}
                <hr />
                <p className={styles.bdm} style={{ backgroundColor: "transparent", color: "black" }}>TX: {certification.cert_wan.hgu_tx.toFixed(2)} dBm</p>
              </div>

              <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                <Router size={40} strokeWidth={1} className={styles.svgTopology} />
                <p style={{ margin: 0, fontWeight: 500 }} className={styles.titleIcon}>HGU</p>
              </div>
            </CardContent>
          </Card>
          <Card className={styles.servicesCert} variant="outlined" sx={{ width: "48%", borderRadius: "10px", minWidth: 300, boxShadow: "-9px 10px 15px #a9a9a92b" }}>
            <CardContent>
              <Typography variant='h5' className={styles.titleServices}>
                <p></p>
                <p></p>
                <p className={styles.title}>
                  Serviços
                </p>
                <span className={styles.online}>
                  {certification.dadosAtividade.connection_state === "Connected" ? <>
                    <div className={styles.circleOn}></div>
                    <p className={styles.onlineInfo}>Online</p>
                  </> :
                    <>
                      <div className={styles.circleOff}></div>
                      <p className={styles.onlineInfo}>Offline</p>
                    </>}
                </span>
              </Typography>

              <div className={certification.dadosAtividade.connection_state === "Connected" ? styles.divService : styles.divServiceOffset} >
                <div style={{ textAlign: "center" }}>
                  <span title={certification.primary_diagnosis.sip_register}>
                    <ServerVoIP />
                    <p style={{ margin: 0 }} className={styles.connectedServices}>
                      {certification.primary_diagnosis.sip_register === "up" ? <>
                        <CircleCheck className={styles.checkedIcon} />
                      </> : certification.primary_diagnosis.sip_register === "unconfigured" ? <>
                        <CircleAlert color="orange" />
                      </> : <>
                        <CircleX className={styles.checkedX} />
                      </>}
                    </p>
                  </span>
                  <p className={styles.subTitleServices}>SIP</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span title={certification.primary_diagnosis.voip_status}>
                    <img src={telephone} alt='telefone' style={{ width: "45px", height: "50px" }} />
                    <p style={{ margin: 0 }} className={styles.connectedServices}>
                      {certification.primary_diagnosis.voip_status === "up" ? <>
                        <CircleCheck className={styles.checkedIcon} />
                      </> : certification.primary_diagnosis.voip_status === "unconfigured" ? <>
                        <CircleAlert color="orange" />
                      </> : <>
                        <CircleX className={styles.checkedX} />
                      </>}
                    </p>
                  </span>
                  <p className={styles.subTitleServices}>VoIP</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span title={certification.primary_diagnosis.iptv_service}>
                    <img src={display} alt='Computador' style={{ width: "45px", height: "50px" }} />
                    <p style={{ margin: 0 }} className={styles.connectedServices}>
                      {certification.primary_diagnosis.iptv_service === "up" ? <>
                        <CircleCheck className={styles.checkedIcon} />
                      </> : certification.primary_diagnosis.iptv_service === "unconfigured" ? <>
                        <CircleAlert color="orange" />
                      </> : <>

                        <CircleX className={styles.checkedX} />
                      </>}
                    </p>
                  </span>
                  <p className={styles.subTitleServices}>TV</p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span title={certification.primary_diagnosis.internet_service}>
                    <img src={wifi} alt='ROUTER' style={{ width: "45px", height: "50px" }} />
                    <p style={{ margin: 0 }} className={styles.connectedServices}>
                      {certification.primary_diagnosis.internet_service === "up" ? <>
                        <CircleCheck className={styles.checkedIcon} />
                      </> : certification.primary_diagnosis.internet_service === "unconfigured" ? <>
                        <CircleAlert color="orange" />
                      </> : <>
                        <CircleX className={styles.checkedX} />
                      </>}
                    </p>
                  </span>
                  <p style={{ margin: 0, fontWeight: 500, textAlign: "start" }}>Internet</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </section>
        <section style={{ marginTop: 10 }}>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className={styles.carousel}
            containerClass="container"
            dotListClass=""
            draggable={false}
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 0,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 0,
              },
              tabletG: {
                breakpoint: {
                  max: 1024,
                  min: 860,
                },
                items: 2,
                partialVisibilityGutter: 0,
              },
              tablet: {
                breakpoint: {
                  max: 860,
                  min: 736,
                },
                items: 1,
                partialVisibilityGutter: 200,
              },
              tabletP: {
                breakpoint: {
                  max: 735,
                  min: 685,
                },
                items: 1,
                partialVisibilityGutter: 170,
              },
              mobileG: {
                breakpoint: {
                  max: 685,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              mobileM: {
                breakpoint: {
                  max: 580,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 50,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {Object.keys(certification.summary_status).map((key, index) => (
              <Card variant="outlined" className={styles.card} sx={{ textAlign: "center", borderRadius: "10px", minWidth: 275, margin: "0px 10px", boxShadow: "-9px 10px 15px #a9a9a92b", height: 490 }}>
                <CardContent className={styles.cardContent} sx={{ height: 405 }}>
                  <Typography variant="h5" component="div" color="#676769" sx={{ fontWeight: 600 }}>
                    {key.slice(5).includes("_") ? key.slice(5).replace("_", " ").toUpperCase() : key.slice(5).toUpperCase()}
                  </Typography>
                  <hr />
                  <Typography component="div" sx={{
                    minWidth: 275, margin: "10px 0px", height: 372, display: "flex",
                    flexDirection: "column", justifyContent: "space-between"
                  }}  >
                    <div className={styles.line_card}>
                      <>
                        {certification.summary_status[key].passed.map((item, index) => (
                          <>
                            <ListItemIcon key={index} sx={{ display: "flex", gap: "1em", fontWeight: 500 }}>
                              <img src={checked} alt="positive" />
                              <ListItemText primary={item.includes("_") ? item.replace("_", " ").toUpperCase() : item.toUpperCase()} sx={{ textAlign: "justify" }} />
                            </ListItemIcon>
                          </>
                        ))}
                      </>
                      <>
                        {certification.summary_status[key].failed.map((item, index) => (
                          <>
                            <ListItemIcon key={index} sx={{ display: "flex", gap: "1em", fontWeight: 500 }}>
                              <img src={negative} alt="negative" />
                              <ListItemText primary={item.includes("_") ? item.replace("_", " ").toUpperCase() : item.toUpperCase()} sx={{ textAlign: "justify" }} />
                            </ListItemIcon>
                          </>
                        ))}
                      </>
                      <>
                        {certification.summary_status[key].warning.map((item, index) => (
                          <>
                            <ListItemIcon key={index} sx={{ display: "flex", gap: "1em", fontWeight: 500 }}>
                              <img src={negative} alt="negative" />
                              <ListItemText primary={item.includes("_") ? item.replace("_", " ").toUpperCase() : item.toUpperCase()} sx={{ textAlign: "justify" }} />
                            </ListItemIcon>
                          </>
                        ))}
                      </>
                    </div>
                    <div className={styles.cardButton}>
                      <AlertDialogSlide title={key.slice(5).includes("_") ? key.slice(5).replace("_", " ").toUpperCase() : key.slice(5).toUpperCase()} data={cert} />
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Carousel>
        </section>
      </> : <>
        <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </>}
    </div>
  );
};

export default Certification;