import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Stack from '@mui/material/Stack';
import {
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';



interface Props {
  xData: string[],
  data: Array<number | null>,
  color: string,
  colorStroke?: string,
}

export default function ColorScale({xData, data, color, colorStroke ='#8884d8'}: Props) {
  return (
    <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
      <LineChart
        height={300}
        series={[
          {
            connectNulls: false , 
            data: data,
            area: true,
          },
        ]}
        margin={{
          top: 10,
          bottom: 20,
        }}
        yAxis={[
          {
            colorMap:{
              type: 'continuous',
              min: 0,
              max: 10,
              color: ['transparent', color,]
            },
            max:10

          },
        ]}
        xAxis={[
          {data: xData, scaleType: "point"},
        ]}

        sx={{
          [`& .${lineElementClasses.root}`]: {
            stroke: colorStroke,
            strokeWidth: 3.5,
          },
          [`& .${markElementClasses.root}`]: {
            stroke: colorStroke,
            scale: '2',
            fill: '#fff',
            strokeWidth: 2
          },
        }}
      />
      
    </Stack>
  );
}
