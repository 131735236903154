
import { useEffect, useState } from "react"


import styles from "./DeviceMap.module.css"

import { DeviceMap, DhcpTable, Summary } from "../../../../../interfaces/DeviceMap";

import CardsDisp from "./CardsDisp";

const DevicesMap = ({ devices } : { devices: DeviceMap }) => {
  const [summary, setSummary] = useState<Summary>()
  const [wifi2gList, setWifi2gList] = useState<DhcpTable[]>()
  const [wifi5gList, setWifi5gList] = useState<DhcpTable[]>()

  const setCertificado = async(res: DeviceMap) => {
    let wifi2list: DhcpTable[] = []
    let wifi5list: DhcpTable[] = []
    res.dhcp_table.forEach(element => {
      if(element.media.includes("2.4")){
        wifi2list.push(element)
      }else if(element.media.includes("5")){
        wifi5list.push(element)
      }
    });

    setSummary(res.summary)
    setWifi2gList(wifi2list)
    setWifi5gList(wifi5list)
  }

  useEffect(() => {
    if(devices){
        setCertificado(devices)
      }
    }, [devices])


  return (
    <div>
        <div className={styles.container}>
          <CardsDisp title="WiFi 2.4GHz" summary={summary} data={wifi2gList} />
          <CardsDisp title="WiFi 5GHz" summary={summary} data={wifi5gList} />
        </div>
    </div>
  )
}

export default DevicesMap