import React, { useEffect, useState } from 'react'

import styles from "./NewWifi.module.css"
import stylesHac from "../Internet/Internet.module.css"

import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useParams } from 'react-router-dom';
import { WifiData, DadosAtividade } from '../../interfaces/CertWifi';
import { WifiCert } from '../../services/api';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import checked from "../../assets/images/checked.png"
import hgu from "../../assets/images/hgu.png"

import AlertDialogSlide from './Topologia';

type Props = {
    Info: DadosAtividade | undefined,
    Image: any
}

const Aside = ({Info, Image}: Props) => {
    return (
      <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:550}}>
                  <CardContent className={stylesHac.asideContent} sx={{padding: "5px", height: 550}}>
                      {Info && <>
                          <Typography className={stylesHac.dateExec} component="p">{Info.sent_time}</Typography>
                          <Typography className={stylesHac.tecLogin} component="div">
                              <span>
                                  <p className={stylesHac.h1Atividade}>Ordem</p>
                                  <p className={stylesHac.labelAtividade}>{Info.ordem} </p>
                              </span>
                              <hr />
                              <span>
                                  <p className={stylesHac.h1Atividade}>Login Técnico</p>
                                  <p className={stylesHac.labelAtividade}>{Info.login_tecnico}</p>
                              </span>
                          </Typography>
                          <hr />
                          <Typography className={stylesHac.hguImage} component='div'>  
                              <img src={Image} alt='HGU Imagem' style={{width : "44%"}}/>
                              <p className={stylesHac.labelAtividade}>{Info.modelo}</p>
                          </Typography>
                          <hr style={{margin: "0"}}/>
                          <Typography className={stylesHac.appVersion}>
                              <div>
                                  <p className={stylesHac.h1Atividade}>Firmware:</p> 
                                  <p className={stylesHac.labelAtividade}>{Info.firmware}</p>
                              </div>
      
                          </Typography>
                          <hr />
                          <Typography className={stylesHac.appVersion}>
                              <div>
                                  <p className={stylesHac.h1Atividade}>Designador Internet:</p>
                                  <p className={stylesHac.labelAtividade}>{Info.designador}</p>
                              </div>
                          </Typography>
                          <hr />
                          <Typography className={stylesHac.appVersion}>
                              <div>
                                  <p className={stylesHac.h1Atividade}>Serial:</p> 
                                  <p className={stylesHac.labelAtividade}>{Info.serial_number}</p>
                              </div>
                              <hr />
                              <div>
                                  <p className={stylesHac.h1Atividade}>Protocolo:</p> 
                                  <p className={stylesHac.labelAtividade}>{Info.protocol_id}</p>
                              </div>
                          </Typography>
                      </>}
                  </CardContent>
              </Card>
    )
  }


const NewWifi = () => {

    const [data, setData] = useState<WifiData | undefined>()

    const { id } = useParams();
    const setCertificado = async(id: string) =>{
        const res = await WifiCert(id)
        setData(res)

    }

    useEffect(() => {
        if(id){
            setCertificado(id)
        }
    }, [id])

    const porcentagem = (valor_base: number, percent: number): number =>{
        const resultado = (percent / valor_base) * 100

        return resultado
    }

    const percentual_ok = porcentagem(parseInt(data?.discovery.qty_total_hosts ? data?.discovery.qty_total_hosts: 
        "0"), parseInt(data?.discovery.hosts_veloc_ok ? data?.discovery.hosts_veloc_ok : "0") -1)

    const percentual_nok = porcentagem(parseInt(data?.discovery.qty_total_hosts ? data?.discovery.qty_total_hosts : "0"),
    parseInt(data?.discovery.hosts_rssi_nok ? data?.discovery.hosts_rssi_nok : '0'))

    let dbmAcima: number = 0
    let dbmbaixo: number = 0

    let dbmBase = -70

    let dbmPercentAcima = 0
    let dbmPercentBaixo = 0

    if(data){   
        for (let i = 1; i < data.discovery.hosts.length; i++) {
            if((parseInt(data.discovery.hosts[i].potencia)) <= dbmBase){
                dbmAcima += 1
            }else{
                dbmbaixo += 1
            }
        }
        dbmPercentAcima = porcentagem(parseInt(data.discovery.qty_total_hosts), dbmAcima)
        dbmPercentBaixo = porcentagem(parseInt(data.discovery.qty_total_hosts), dbmbaixo)
    }

  return (
    <div>
        <section className={styles.container_top}>
            <article className={styles.graphics_hosts}>
                <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", marginBottom: "5px", height: "99%"}}>
                    <CardContent className={styles.graphics}>
                        <Card  variant="outlined" sx={{width: "100%" ,borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", marginBottom: "5px", height: "99%"}}>
                            <CardContent sx={{textAlign: "center"}} className={styles.container_graphics}>
                                <div className={styles.div_grapic}>
                                    <CircularProgressbarWithChildren  styles={buildStyles ({trailColor: "#c9c9c9", pathColor: '#5065DA'})} value={percentual_ok}>
                                        <div className={styles.divGraphic} style={{ marginTop: "-7px"}}>
                                            <strong className={styles.label_progress}>{percentual_ok}%</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    <p style={{width: "100%", margin: 0}}>{data?.discovery.qty_total_hosts} Dispositivos com velocidade igual ou acima de {data?.dadosAtividade.veloc_contratada} Mbps</p>
                                </div>
                                
                                <div className={styles.div_grapic}>
                                    <CircularProgressbarWithChildren  styles={buildStyles ({trailColor: "#c9c9c9", pathColor: '#5065DA'})} value={percentual_nok}>
                                        <div style={{ marginTop: "-7px"}}>
                                            <strong className={styles.label_progress}>{percentual_nok}%</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    <p style={{width: "100%", margin: 0}}>{data?.discovery.qty_total_hosts} Dispositivos com velocidade igual ou acima de {data?.dadosAtividade.veloc_contratada} Mbps</p>
                                </div>
                            </CardContent> 
                        </Card>     
                       
                        <Card  variant="outlined" sx={{width: "100%" ,borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", marginBottom: "5px", height: "99%"}}>
                            <CardContent sx={{textAlign: "center"}} className={styles.container_graphics}>
                                <div className={styles.div_grapic}>
                                    <CircularProgressbarWithChildren  styles={buildStyles ({trailColor: '#c9c9c9', pathColor: '#5065DA' })} value={dbmPercentAcima}>
                                        <div className={styles.divGraphic} style={{ marginTop: "-7px"}}>
                                            <strong className={styles.label_progress}>{dbmPercentAcima}%</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    <p>Dispositivos com potência Wi-Fi acima de -70 dBm</p>
                                </div>
                                
                                <div className={styles.div_grapic}>
                                    <CircularProgressbarWithChildren  styles={buildStyles ({trailColor: '#c9c9c9', pathColor: "#FFC230"})} value={dbmPercentBaixo}>
                                        <div className={styles.divGraphic} style={{ marginTop: "-7px"}}>
                                            <strong className={styles.label_progress}>{dbmPercentBaixo}%</strong>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    <p>Dispositivos com potência Wi-Fi Abaixo de -70 dBm</p>
                                </div>
                            </CardContent> 
                        </Card>     
                            
                    </CardContent> 
                </Card>            
                
                <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", marginTop: "5px", height: 390}}>
                    <CardContent>
                        <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", marginTop: "5px", height: "99%"}}>
                            <CardContent>
                                <TableContainer sx={{height: 305}} >
                                        <Table sx={{ minWidth: 650}} size="small" aria-label="a dense table">
                                            <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Dessert (100g serving)</TableCell> */}
                                                <TableCell align="center">Hostname</TableCell>
                                                <TableCell align="center">IP</TableCell>
                                                <TableCell align="center">Vel.Máx</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {data?.discovery.hosts.map((item) => (
                                                <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                
                                                <TableCell align="center">{item.hostname}</TableCell>
                                                <TableCell align="center">{item.ipaddr}</TableCell>
                                                <TableCell align="center">{item.max_phyrate_hw}</TableCell>
                                                <TableCell align="center"><img src={checked} alt="positive" /></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent> 
                        </Card>            
                    </CardContent> 
                </Card>            
            </article>
            <aside className={styles.hgu_info}>
                <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:"98%"}}>
                    <CardContent>
                        <Aside Info={data?.dadosAtividade} Image={hgu} />
                    </CardContent> 
                </Card>   
            </aside>
        </section>
        <section className={styles.container_bottom}>
            <Card  variant="outlined" sx={{width: "56%",borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:"98%"}}>
                <CardContent>
                    <Card  variant="outlined" sx={{width: "100%",borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:"98%"}}>
                        <CardContent>
                        <TableContainer sx={{ height: 180}}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Cômodos</TableCell>
                                        <TableCell align="center">RSSI</TableCell>
                                        <TableCell align="center">Andar</TableCell>
                                        <TableCell align="center">Device</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.cards.map((item) => (
                                        <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >                                        
                                        <TableCell align="center">{item.comodo}</TableCell>
                                        <TableCell align="center">{item.rssi_measured[0]}</TableCell>
                                        <TableCell align="center">{item.andar}</TableCell>
                                        <TableCell align="center">{item.tipo}</TableCell>
                                        <TableCell align="center"><img src={checked} alt="positive" /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </CardContent> 
                    </Card> 
                </CardContent> 
            </Card> 
            <Card  variant="outlined" sx={{width: "43%", borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:"98%"}}>
                <CardContent>
                    <Card  variant="outlined" sx={{borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height:200}}>
                        <CardContent >
                            <div className={styles.device_group}>
                                <div className={styles.hgu_photo}>
                                    <img src={hgu} alt="HGU" className={styles.icon_hgu} />
                                    <p >HGU</p>
                                </div>
                                <div>
                                    <span className={styles.icon}>
                                        <span className="material-symbols-outlined">
                                            settings_remote
                                            {/* modo cabeadoo */}
                                        </span>
                                        <p>{data?.discovery.qty_bp}</p>
                                    </span>
                                    <p>Repetidor Cabeado</p>
                                </div>
                                <div>
                                    <span className={styles.icon}>
                                        <span className="material-symbols-outlined">
                                                wifi_tethering
                                            {/* modo wifi */}
                                        </span>
                                        <p>1</p>
                                    </span>
                                    <p>Repetidor WiFi</p>
                                </div>
                            </div>
                            <span style={{width: "100%", textAlign: "end", display: "block"}}>
                                {data&&<AlertDialogSlide topologia={data.discovery.topologia} />}
                            </span>
                        </CardContent> 
                    </Card> 
                </CardContent> 
            </Card> 
        </section>
    </div>
  )
}

export default NewWifi